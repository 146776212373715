import HttpClient from '../../utils/HttpClient';
import { 
    SearchResultsMockupData,
} from '../../sampleMockupData/searchResultsMockupData';
import {
    REST_SEARCH_DROPDOWN_DATA_PATH_V2,
    REST_SEARCH_PATH_V2,
} from '../../constants/Rest.constants';

export default class SearchService {

    static getDropDownData(team) {
        let params = {
            team: team
        }
        return HttpClient.get(REST_SEARCH_DROPDOWN_DATA_PATH_V2, params);
    }

    static getAnalystDropDownData() {
        return HttpClient.get(REST_SEARCH_DROPDOWN_DATA_PATH_V2+"/analysts");
    }

    static filterEquitiesAnalysts(dropDownData) {
        var _dropdownData = dropDownData;
        _dropdownData = SearchService.filterAnalystsByFirmName(_dropdownData);
        return _dropdownData;
    }

    static filterAnalystsByFirmName(dropDownData, firmName) {
        if (!dropDownData) { return null; }
        var _dropdownData = dropDownData;
        _dropdownData.analysts = _dropdownData.analysts.filter( analyst => analyst.firmName === firmName);
        return _dropdownData;
    }

    static filterDocumentTypeByFirmName(dropDownData) {
        if (!dropDownData) { return null; }
        let dropdownDataTmp = dropDownData;
        let dropDownBmxmlIdMap = [];
        dropdownDataTmp.documentTypes.forEach(documentType => {
            dropDownBmxmlIdMap.push({
                label: documentType.label,
                value: documentType.firmBmXmlIds
            })
        })
        dropdownDataTmp.documentTypes = dropDownBmxmlIdMap;
        return dropdownDataTmp; 
    }

    static filterDropDownDataByFirmName(dropDownData, firmName) {
        if (!dropDownData) { return null; }
        let _dropdownData = { ...dropDownData };
        _dropdownData = SearchService.filterAnalystsByFirmName(_dropdownData, firmName);
        _dropdownData = SearchService.filterDocumentTypeByFirmName(_dropdownData);
        return _dropdownData;
    }

    static getSearchResults(params) {
        return HttpClient.post(REST_SEARCH_PATH_V2, params, {});
    }

    static getAnalystSearch() {
        const promiseMockup = new Promise((resolve, reject) => { 
            setTimeout(() => { 
                resolve(SearchResultsMockupData); 
            }, 5000); 
        }); 
        return promiseMockup;
    }

}