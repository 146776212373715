import React from "react";
import { withRouter } from "react-router-dom";
import HeaderNavMobileComponent from "./mobile/HeaderNavMobile.component";
import HeaderNavLoginComponent from "./HeaderNavLogin.component";
import HeaderNavBarComponent from "./HeaderNavBar.component";
import LoginService from "../../services/Login.service";
import {
  HeaderTopNavMenuData,
  HeaderTopNavUserMenuData,
  HeaderMainNavMenuData,
  HeaderMainNavMenuDataCowen,
} from "../../constants/HeaderNav.constants";
import ModalComponent from "../misc/modal/Modal.component";
import RequestMeetingComponent from "../request-meeting/RequestMeeting.component";

import { KEY_ENTER } from '../../constants/Misc.constants';
import {
  TDS_TOP_NAV,
  TDS_TOP_NAV_RIGHT,
  TDS_TOP_NAV_DROPDOWN_LINK,
  TDS_DROPDOWN_CONTENT,
  TDS_DROPDOWN_CONTENT_USER,
  TDS_NAVBAR,
  TDS_NAVBAR_DROPDOWN_LINK,
  MODAL_TYPE_DEFAULT,
  MODAL_TYPE_EQUITIES,
  MODAL_TYPE_OTHER,
  MODAL_TYPE_CROSS_ASSETS_CREDIT,
} from "../../constants/HeaderNav.constants";

import {
  LINK_TDSECURITIES,
  LINK_TDSECURITIES_EQUITY,
  LINK_SEARCH_PAGE
} from "../../constants/LinkPaths.constants";
import { REQUEST_ACCESS_MAIL_FROM_MAILTO, REQUEST_ACCESS_MAIL_FROM } from "../../constants/LandingPage.constants";
import { isCowen, isCowenEnabled } from "../../utils/valueUtils";
import CowenFlagContext from "../../system/CowenFlagContext";


class HeaderNavComponent extends React.Component {
  static contextType = CowenFlagContext;
  constructor(props) {
    super(props);
    this.topNavSectionLeft = React.createRef();
    this.topNavSectionRight = React.createRef();
    this.mainNav = React.createRef();
    this.headerModal = React.createRef();
    this.requestMeetingModal = React.createRef();

    this.state = {
      search: false,
      user: this.props.user,
      userMenu: HeaderTopNavUserMenuData,
      searchkeywords: "",
      userFullName: "",
      cowenEnabled: false,
      modalType: 'default'
    };
    if (this.props.user) {
      HeaderTopNavUserMenuData[0].text =
        this.props.user.firstName + " " + this.props.user.lastName;
      HeaderTopNavUserMenuData[0].title =
        this.props.user.firstName + " " + this.props.user.lastName;
      this.state.userFullName =
        this.props.user.firstName + " " + this.props.user.lastName;
    }
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleSearchOpenClick = this.handleSearchOpenClick.bind(this);
    this.handleSearchCloseClick = this.handleSearchCloseClick.bind(this);
    this.handleOnMouseLeaveTopNavBar = this.handleOnMouseLeaveTopNavBar.bind(
      this
    );
    this.handleOnMouseEnterTopNavBar = this.handleOnMouseEnterTopNavBar.bind(this);
    this.handleSetSearchValue = this.handleSetSearchValue.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showMeeting = this.showMeeting.bind(this);
    this.hideMeeting = this.hideMeeting.bind(this);
    this.handleOnMouseLeaveMainNavBar = this.handleOnMouseLeaveMainNavBar.bind(this);
  }

  componentDidMount() {
    isCowen().then(r => {
      this.setState({ cowenEnabled: r })
    }).catch(e => {
      console.log(e);
    });
  }

  handleLoginClick(event) {
    window.location.href = LoginService.getLoginURL();
  }
  handleSearchOpenClick(event) {
    this.setState(() => ({ search: true }));
  }
  handleSearchCloseClick(event) {
    this.setState(() => ({ search: false }));
  }
  handleSearchClick = (event, cowenFlag) => {
    this.handleSearchCloseClick(event);
    this.props.history.push(LINK_SEARCH_PAGE + '?keywords=' + this.state.searchkeywords);
    this.setState({
      searchkeywords: ""
    });
  }
  handleKeyPress = (event, cowenFlag) => {
    if (event.key === KEY_ENTER) {
      this.handleSearchClick(event, cowenFlag);
    }
  }
  handleSetSearchValue(event) {
    this.setState({ searchkeywords: event.target.value });
  }
  handleOnMouseLeaveTopNavBar(event) {
    if (this.topNavSectionLeft && this.topNavSectionLeft.current) {
      this.topNavSectionLeft.current.closeMenus();
    }
    if (this.topNavSectionRight && this.topNavSectionRight.current) {
      this.topNavSectionRight.current.closeMenus();
    }
  }
  handleOnMouseEnterTopNavBar() {
    if (this.mainNav && this.mainNav.current) {
      this.mainNav.current.closeMenus();
    }
  }
  showModal(modalType) {
    if (modalType) {
      this.setState({
        modalType: modalType
      }, this.headerModal.current.showModal())
    } else {
      this.headerModal.current.showModal()
    }
  }
  showMeeting(e) {
    if (e) {
      e.preventDefault();
    }
    this.requestMeetingModal.current.showModal();
  }
  hideMeeting() {
    this.requestMeetingModal.current.hideModal();
  }
  handleOnMouseLeaveMainNavBar() {
    if (this.mainNav && this.mainNav.current) {
      this.mainNav.current.closeMenus();
    }
  }
  handleOnMouseEnterMainNavBar() {
    if (this.topNavSectionLeft && this.topNavSectionLeft.current) {
      this.topNavSectionLeft.current.closeMenus();
    }
    if (this.topNavSectionRight && this.topNavSectionRight.current) {
      this.topNavSectionRight.current.closeMenus();
    }
  }
  render() {
    const cowenFlag = this.context;
    return (
      <div>
        <header>
          <div className="td-bg-colour-2 tds-header">
            <div className="tds-container">
              <div
                className="tds-section-left"
                onMouseLeave={this.handleOnMouseLeaveTopNavBar}
                onMouseEnter={this.handleOnMouseEnterTopNavBar}
              >
                <a
                  href={LINK_TDSECURITIES}
                  className="tds-logo">
                  <img
                    src="/assets/img/tds_shield.svg"
                    title="TD Securities"
                    alt="TD Securities"
                  />
                </a>
                {this.props.authenticated && (
                  <HeaderNavBarComponent
                    ref={this.topNavSectionLeft}
                    menuItems={HeaderTopNavMenuData}
                    topClassName={TDS_TOP_NAV}
                    dropDownClassName={TDS_TOP_NAV_DROPDOWN_LINK}
                    dropDownContentClassName={TDS_DROPDOWN_CONTENT}
                    hover={false}
                    user={this.state.user}
                    showModal={this.showModal}
                  />
                )}
              </div>
              {!this.props.authenticated && (
                <HeaderNavLoginComponent onClick={this.handleLoginClick} />
              )}
              {this.props.authenticated && (
                <div
                  className="tds-section-right"
                  onMouseLeave={this.handleOnMouseLeaveTopNavBar}
                  onMouseEnter={this.handleOnMouseEnterTopNavBar}
                  style={
                    {
                      'minWidth': '100px'
                    }
                  }
                >
                  {!this.props.hideMeetingIcon &&
                    <div className="tds-top-nav right">
                      <div className="tds-nav-item request-a-meeting">
                        <a
                          href="# "
                          className="tds-top-nav-dropdown-link"
                          title="Request a meeting"
                          onClick={this.showMeeting}
                        >
                          <span className="td-icon td-icon-bookAppointment icon-small"></span>
                          Request a meeting
                        </a>
                      </div>
                    </div>
                  }
                  <HeaderNavBarComponent
                    ref={this.topNavSectionRight}
                    menuItems={HeaderTopNavUserMenuData}
                    topClassName={TDS_TOP_NAV_RIGHT}
                    dropDownClassName={TDS_TOP_NAV_DROPDOWN_LINK}
                    dropDownContentClassName={TDS_DROPDOWN_CONTENT_USER}
                    hover={false}
                    showModal={this.showModal}
                  />
                </div>
              )}
            </div>
          </div>
          {this.props.authenticated && (
            <div className="tds-nav" onMouseLeave={this.handleOnMouseLeaveMainNavBar}>
              <div className="tds-container">
                <nav className="tds-nav-container">
                  <HeaderNavBarComponent
                    ref={this.mainNav}
                    menuItems={isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? [...HeaderMainNavMenuDataCowen] : [...HeaderMainNavMenuData]}
                    topClassName={TDS_NAVBAR}
                    dropDownClassName={TDS_NAVBAR_DROPDOWN_LINK}
                    dropDownContentClassName={TDS_DROPDOWN_CONTENT}
                    hover={true}
                    showModal={this.showModal}
                  />
                </nav>
                {!this.props.hideSearchBar &&
                  <div className="tds-search-container">
                    <span
                      tabIndex="0"
                      title="Search"
                      className="td-icon td-icon-search icon-search"
                      onClick={this.handleSearchOpenClick}
                    ></span>
                  </div>
                }
              </div>
            </div>
          )}
          {this.state.search && (
            <div className="tds-search-bar-container">
              <div className="tds-container">
                <div className="tds-search-input-container form-group">
                  <input
                    placeholder="Search"
                    name="Search"
                    type="text"
                    className="form-control search"
                    id="search"
                    onChange={this.handleSetSearchValue}
                    onKeyDown={(e) => this.handleKeyPress(e, cowenFlag)}
                  />
                  <span className="td-icon td-icon-search icon-search-bar" onClick={() => this.handleSearchClick(this, cowenFlag)} title="Search"></span>
                  <span
                    className="td-icon td-icon-close icon-search-bar"
                    onClick={this.handleSearchCloseClick}
                    title="Close Search Bar"
                  ></span>
                </div>
              </div>
            </div>
          )}
        </header>
        <ModalComponent
          ref={this.headerModal}
          id={'headerModal'}
          className={'tds-header-modal'}
        >
          {this.state.modalType === MODAL_TYPE_DEFAULT &&
            <React.Fragment>
              <h1>Request Access</h1>
              <p>To navigate complex investment climates, trust our Global Strategy team to provide a wide range of relevant macro research, analysis and trade strategy. Email <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM_MAILTO}>{REQUEST_ACCESS_MAIL_FROM}</a> to request access to this content.</p>
            </React.Fragment>
          }
          {this.state.modalType === MODAL_TYPE_EQUITIES &&
            <React.Fragment>
              <p>Click <a title="TD Securities Equity Research" href={LINK_TDSECURITIES_EQUITY}>here</a> to access TD Securities Equity Research; comprehensive coverage from one of Canada's leaders in equity research, institutional block trading, underwriting and distribution.</p>
            </React.Fragment>
          }
          {this.state.modalType === MODAL_TYPE_CROSS_ASSETS_CREDIT &&
            <React.Fragment>
              <h1>Coming Soon</h1>
              <p>This feature is Coming Soon.</p>
              <p>Be sure to check back for comprehensive and detailed coverage from our top Analysts.</p>
            </React.Fragment>
          }
          {this.state.modalType === MODAL_TYPE_OTHER &&
            <React.Fragment>
              <h1>Request Access</h1>
              <p>TD-sponsored ABCP conduits feature strong credit enhancement and benefits from TD's liquidity support. Email <a href={REQUEST_ACCESS_MAIL_FROM_MAILTO} title={REQUEST_ACCESS_MAIL_FROM}>{REQUEST_ACCESS_MAIL_FROM}</a> to request access to asset performance updates and supplementary info.</p>
            </React.Fragment>
          }
        </ModalComponent>
        {!this.props.hideMeetingIcon &&
          <ModalComponent
            ref={this.requestMeetingModal}
            id={'requestMeetingModal'}
            className={'tds-request-meeting'}
          >
            <RequestMeetingComponent hideMeeting={this.hideMeeting} />
          </ModalComponent>
        }
        {this.props.authenticated && (
          <HeaderNavMobileComponent
            authenticated={this.props.authenticated}
            user={this.state.userFullName}
            handleLogin={() => this.handleLoginClick()}
            showModal={this.showModal}
            hideSearchBar={this.props.hideSearchBar}
            showMeeting={this.showMeeting}
            hideMeetingIcon={this.props.hideMeetingIcon}
          />
        )}
        {!this.props.authenticated && (
          <HeaderNavMobileComponent
            authenticated={this.props.authenticated}
            user=""
            handleLogin={() => this.handleLoginClick()}
            showModal={this.showModal}
            hideSearchBar={this.props.hideSearchBar}
            showMeeting={this.showMeeting}
            hideMeetingIcon={this.props.hideMeetingIcon}
          />
        )}
      </div>
    );
  }
}

export default withRouter(HeaderNavComponent);
