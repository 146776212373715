import React, {useEffect} from 'react';
import EnvironmentService from "../../services/Environment.service";
import {HTTP, HTTPS} from "../../constants/Misc.constants";
import {REST_LOGOUT_PATH} from "../../constants/Rest.constants";
import Spinner from "../spinner/Spinner.component";

export const Logout = () => {

    useEffect(() => {
        if (!EnvironmentService.isLocal()) {
            window.location.href = HTTPS + EnvironmentService.hostname + REST_LOGOUT_PATH;
        } else {
            window.location.href = HTTP + EnvironmentService.hostname + ':' + EnvironmentService.port + REST_LOGOUT_PATH;
        }
    });

    return (
        <div className="tds-container">
            <div className="tds-container">
                <Spinner minHeight={1000} marginTop={50}/>
            </div>
        </div>
    );
}