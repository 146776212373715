import { REST_MONEY_TALK_FLAG_PATH, REST_MONEY_TALK_PAGE } from '../constants/Rest.constants';
import HttpClient from '../utils/HttpClient';


export default class MoneyTalkService {

    static async getData(params) {
        return await HttpClient.get(REST_MONEY_TALK_PAGE, params, {});
    }

    static async getMoneyTalkFlag() {
        return await HttpClient.get(REST_MONEY_TALK_FLAG_PATH);
    }
}