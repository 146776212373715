import {REST_FEATURES_IS_ENABLED} from "../constants/Rest.constants";
import HttpClient from "./HttpClient";

const crypto11 = require('crypto');
const { TEAM, OVERVIEW_PAGE, FIRM_NAME_CA, FIRM_NAME_US, FIRM_NAME_WA, FIRM_NAME_STRATEGY } = require('../constants/Misc.constants');
const { ROUTE_PATH_EQUITIES_CA_RESEARCH_PAGE, ROUTE_PATH_EQUITIES_US_RESEARCH_PAGE, ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_PAGE, ROUTE_PATH_EQUITIES_CA_OVERVIEW_PAGE, ROUTE_PATH_EQUITIES_US_OVERVIEW_PAGE, ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_OVERVIEW_PAGE } = require('../constants/RoutePaths.constants');


export function isNotEmpty(str) {
    return str !== undefined && str !== null && str.toString().trim().length > 0;
}

export function isEmpty(str) {
    return !isNotEmpty(str);
}

export function isNotEmptyArray(array) {
    return array && array.length !== undefined && array.length > 0;
}

export function isEmptyArray(array) {
    return !isNotEmptyArray(array);
}

export function isNotEmptyStringArray(array) {
    if (isNotEmptyArray(array)) {
        if (array.filter(value => isNotEmpty(value)).length > 0) return true;
    }
    return false;
}

export function getValueInPercentage(value) {
    let convertedValue = value * 100;
    if (convertedValue.toString().indexOf(".") > 0) {
        convertedValue = toFixedDecimalLength(convertedValue, 5);
    }
    return convertedValue + '%';
}

export const addDecimalPlaceByNumber = (value, number) => {
    if (value) {
        value = value.toString();
        if (value.indexOf(".") === -1) {
            return parseFloat(value).toFixed(number);
        } else {
            const subValue = value.substring(value.indexOf(".") + 1, value.length);
            if (subValue.length >= 1) {
                return parseFloat(value).toFixed(number);
            }
        }
    }
}

/**
* Generates a pseudorandom number. Replaces Math's random().
*/
export const randomNumber = () => {
    const random = parseFloat('0.' + parseInt(crypto11.randomBytes(8).toString('hex')));

    return random;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toFixedDecimalLength(value, decimalLength) {
    var re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimalLength || -1) + '})?');
    return value.toString().match(re)[0];
}

export function displayArray(array, separator) {
    if (isEmptyArray(array)) return '';
    let _separator = (separator) ? separator : ', ';

    return array.map((item, i) => (i !== 0 ? _separator : '') + item.toString());
}

export function replaceURLsWithLinks(message) {
    if (isEmpty(message)) return;

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
        let hyperlink = url;
        if (!hyperlink.match('^https?://')) {
            hyperlink = 'https://' + hyperlink;
        }
        return `<a href=${hyperlink} target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
}

export const isCowen = () => {
    return Promise.resolve(true); //This is temporary fix for ticket GSBM-7740. will be remove altoghter after code cleanup.
} 

export const isCowenEnabled = (cowenEnabledFromEnv, cowenFlag) => {
    return (cowenEnabledFromEnv === true ? cowenEnabledFromEnv : cowenFlag);
}

export const isOpmAuthEnabled = async () => {
    return HttpClient.get(REST_FEATURES_IS_ENABLED + "opm-auth");
}

export const getRoutePath = (team, type) => {
  switch (team) {
    case TEAM.CA:
      return type === OVERVIEW_PAGE
        ? ROUTE_PATH_EQUITIES_CA_OVERVIEW_PAGE
        : ROUTE_PATH_EQUITIES_CA_RESEARCH_PAGE;
    case TEAM.US:
      return type === OVERVIEW_PAGE
        ? ROUTE_PATH_EQUITIES_US_OVERVIEW_PAGE
        : ROUTE_PATH_EQUITIES_US_RESEARCH_PAGE;
    case TEAM.WRG:
      return type === OVERVIEW_PAGE
        ? ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_OVERVIEW_PAGE
        : ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_PAGE;
    default:
      return type === OVERVIEW_PAGE
        ? ROUTE_PATH_EQUITIES_CA_OVERVIEW_PAGE
        : ROUTE_PATH_EQUITIES_CA_RESEARCH_PAGE;
  }
};

export const getTeam = (divisionId) => {
    switch (divisionId) {
      case 6:
        return TEAM.CA;
      case 2:
        return TEAM.US;
      case 3:
        return TEAM.WRG;
      default:return "strategy";
    }
  };

  export const getExactFirmName = (team) => {
    switch (team) {
      case TEAM.CA:
        return FIRM_NAME_CA;
      case TEAM.US:
        return FIRM_NAME_US;
      case TEAM.WRG:
        return FIRM_NAME_WA;
      default:return FIRM_NAME_STRATEGY;
    }
  };