import React from "react"
import { executeInnerHtml } from '../../services/Utils.service';
import { Link } from "react-router-dom";
import { LINK_MONEY_TALK_INTERVIEWS_PAGE } from '../../constants/LinkPaths.constants';
import { TDS_DATE_FORMAT } from '../../constants/Misc.constants';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';

export default function MoneyTalkSection(props) {

const handleTagChange = (tag) => {
    props.setTagData(tag);
}

const getTagsData = (tagData) => {
    return (
       tagData.split('$$').map((tag, index) => {
        return (
            <Link
                 key={index}
                 to={LINK_MONEY_TALK_INTERVIEWS_PAGE+"?tag="+tag}
                 title={tag}
                 onClick={() => handleTagChange(tag)}
             >
                 {tagData.split('$$').length !== index+1 ? tag+", " : tag}
             </Link>)
     }) 
    )  
}
return (
        <React.Fragment>
            {
                props.data &&
                <div className="tds-money-talk-row-section">
                    <div className="tds-money-talk-row">
                        <div className="tds-money-talk-column image">
                            <img className="tds-money-talk-img size" src={props.data.imageUrl} alt={props.data?.title}></img>
                            <span className="td-icon td-icon-videoPlayButtonCircle" />
                        </div>
                        <div className="tds-money-talk-column detail">
                                <span
                                    className="tds-money-talk-expanded-title"
                                    title={props.data?.title}
                                >
                                    { props.data?.title ? executeInnerHtml(props.data?.title) :"" }
                                </span>
                                { props?.data?.postDate &&
                                    <React.Fragment>
                                        <div className="tds-money-talk-value">
                                        <FormatedDateComponent date={props.data.postDate} format={TDS_DATE_FORMAT}/>
                                        </div>
                                    </React.Fragment>
                                }
                                { props?.data?.content &&
                                    <React.Fragment>
                                        <div className="tds-money-talk-value">
                                            { executeInnerHtml(props.data.content) }
                                        </div>
                                    </React.Fragment>
                                }
                                { props?.data?.tags && 
                                    <span className="tds-money-talk-expanded-description">
                                        <div className="tds-money-talk-label">
                                        Tags:
                                        </div>
                                        <div className="tds-money-talk-value">
                                                <React.Fragment>
                                               {
                                                   getTagsData(props.data.tags)
                                               }
                                                </React.Fragment>
                                        </div>
                                    </span> 
                                }
                            </div>
                        </div>
                </div>
            }
        </React.Fragment>
    )
}
