import { useEffect, useState } from "react";
import SpinnerComponent from "../spinner/Spinner.component";
import CompanyService from "../../services/Company.service";

function TimeSeriesGridComponent(props) {
 const [results, setResults] = useState();
 const [loading, setLoading] = useState(false);

 useEffect(() => {
 const getError = () => {
      setLoading(false);
      setResults(null);
  }
    setLoading(true);
    CompanyService.getTimeSeriesGridData(props.issuerId).then(results => {
        setResults(results);
        setLoading(false);
    }).catch(getError);
    //setResults(TIME_SERIES_GRID_MOCKUP.data);  //to test with mock data
 }, [props.issuerId]);

  const getRowData = (subHeaderData) => {
      let subHeaderDataTmp = [];
      subHeaderDataTmp.push(getDataSetByType(subHeaderData, "Q1"));
      subHeaderDataTmp.push(getDataSetByType(subHeaderData, "Q2"));
      subHeaderDataTmp.push(getDataSetByType(subHeaderData, "Q3"));
      subHeaderDataTmp.push(getDataSetByType(subHeaderData, "Q4"));
      subHeaderDataTmp.push(getDataSetByType(subHeaderData, "Annual"));
      return subHeaderDataTmp;
   }

   const getDataSetByType = (subHeaderData, type) => {
      const typeData = subHeaderData.find(rowData => rowData.period === type);
      if(typeData) {
        return typeData;
      } else if(typeData === undefined) {
          return {
            "period": type,
            "value": 0,
            "displayValue": "NA"
          };
      }
   }

   const getRowDataToDisplay = (subHeaderData) => {
    const rowDataArr = getRowData(subHeaderData);

    return rowDataArr.map(rowData => {
      switch (rowData?.period) {
        case "Q1":
          return <div className="tds-time-series-grid-column q1">{rowData?.displayValue}</div>
        case "Q2":
          return  <div className="tds-time-series-grid-column q2">{rowData?.displayValue}</div>
        case "Q3":
          return  <div className="tds-time-series-grid-column q3">{rowData?.displayValue}</div>
        case "Q4":
          return  <div className="tds-time-series-grid-column q4">{rowData?.displayValue}</div>
        case "Annual":
          return  <div className="tds-time-series-grid-column annual">{rowData?.displayValue}</div>
        default:
          return <div></div>;
      }
     })
    }
 
  const showSpinner = () => loading && <SpinnerComponent />;

  return (
    <>
    {showSpinner()}
    { !showSpinner() && results && 
      <div className="tds-time-series-grid-container">
            <><section className="tds-time-series-grid">     
          <div className="tds-time-series-grid-card">
              {
                results?.data?.map((header, index) => {
                    return(
                      <>
                        <div className="tds-time-series-grid-label" key={index}>
                          {header.name}
                        </div>
                        <div className="tds-time-series-grid-table" key={index}>
                          <div className="tds-time-series-grid-row header">                      
                            <div className="tds-time-series-grid-column year">
                              Year
                            </div>
                            <div className="tds-time-series-grid-column q1">
                              Q1
                            </div>
                            <div className="tds-time-series-grid-column q2">
                              Q2
                            </div>
                            <div className="tds-time-series-grid-column q3">
                              Q3
                            </div>
                            <div className="tds-time-series-grid-column q4">
                              Q4
                            </div>
                            <div className="tds-time-series-grid-column annual">
                              Annual
                            </div>
                          </div>
                          {header.values.map(subHeader => {
                           
                                return (<><div className="tds-time-series-grid-row subHeader">
                                  <div className="tds-time-series-grid-column year">{subHeader.year}</div>
                                  
                                  {
                                      getRowDataToDisplay(subHeader.values)
                                  }</div></>)
                              })}
                      </div></>
                    )
                })
              }
          </div>
        </section>
        </>
      </div>
    }</>
 )
}

export default TimeSeriesGridComponent;