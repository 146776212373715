import React from 'react';
import { withRouter } from "react-router-dom";
import HeaderNavBarMobileComponent from './HeaderNavBarMobile.component';
import { HeaderMainNavMenuDataCowen, TDS_ACTIVE } from '../../../constants/HeaderNav.constants';
import {
    HeaderMainNavMenuData,
    HeaderTopNavMenuData,
    HeaderMobileUserNavData,
} from '../../../constants/HeaderNav.constants';
import { KEY_ENTER } from '../../../constants/Misc.constants';
import {
    LINK_TDSECURITIES,
    LINK_SEARCH_PAGE
  } from "../../../constants/LinkPaths.constants";
import { isCowen, isCowenEnabled } from '../../../utils/valueUtils';
import CowenFlagContext from "../../../system/CowenFlagContext";

class HeaderNavMobileComponent extends React.Component {
    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {
            navLeftActive: false,
            navRightActive: false,
            cowenEnabled: false,
            searchkeywords: "",
        };
        this.handleOpenNavLeft = this.handleOpenNavLeft.bind(this);
        this.handleOpenNavRight = this.handleOpenNavRight.bind(this);
        this.handleCloseNavLeft = this.handleCloseNavLeft.bind(this);
        this.handleCloseNavRight = this.handleCloseNavRight.bind(this);
        this.handleOnLoginClick = this.handleOnLoginClick.bind(this);
        this.stopBodyScroll = this.stopBodyScroll.bind(this);
        this.startBodyScroll = this.startBodyScroll.bind(this);
        this.handleSetSearchValue = this.handleSetSearchValue.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.showMeeting = this.showMeeting.bind(this);
    }

    componentDidMount() {
        isCowen().then(r =>{
            this.setState({cowenEnabled: r})
        }).catch(e =>{
            console.log(e);
        });
    }

    stopBodyScroll() {
        document.body.style.overflow = "hidden";
    }
    startBodyScroll() {
        document.body.style.overflow = "auto";
    }
    handleOpenNavLeft(event) {
        window.scrollTo({ top: 0 });
        this.stopBodyScroll();
        this.setState({ navLeftActive: true });
    }
    handleOpenNavRight(event) {
        window.scrollTo({ top: 0 });
        this.stopBodyScroll()
        this.setState({ navRightActive: true });
    }
    handleCloseNavLeft(event) {
        this.startBodyScroll();
        this.setState({ navLeftActive: false });
    }
    handleCloseNavRight(event) {
        this.startBodyScroll();
        this.setState({ navRightActive: false });
    }
    handleOnLoginClick(event) {
        this.props.handleLogin(event);
    }
    handleLeftMenuItemClick = () => {
        this.handleCloseNavLeft();
    }
    handleRightMenuItemClick = () => {
        this.handleCloseNavRight();
    }
    showModal(modalType) {
        if (modalType) {
            this.setState({
                modalType: modalType
            }, this.headerModal.current.showModal())
        } else {
            this.headerModal.current.showModal()
        }
    }
    handleKeyPress = (event, cowenFlag) => {
        if (event.key === KEY_ENTER) {
            this.handleCloseNavLeft();
            this.props.history.push((isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? LINK_SEARCH_PAGE : LINK_SEARCH_PAGE) + '?keywords=' + this.state.searchkeywords);
        }
    }
    handleSetSearchValue(event) {
        this.setState({ searchkeywords: event.target.value });
    }
    handleSearchClick = (event) => {
        this.props.history.push(LINK_SEARCH_PAGE + '?keywords=' + this.state.searchkeywords);
        this.setState({
            searchkeywords: ""
        });
        this.handleCloseNavLeft(event);
    }
    showMeeting() {
        this.props.showMeeting();
    }
    render() {
        const cowenFlag = this.context;
        return (
            <header className="tds-mobile-nav">
                <div className="tds-mobile-nav-header">
                    {
                        this.props.authenticated &&
                        <span className="td-icon td-icon-hamburger icon-mobile"
                            title="Open Menu"
                            onClick={this.handleOpenNavLeft}></span>
                    }
                    <a
                        href={LINK_TDSECURITIES}
                        className="tds-logo-mobile">
                        <img src="/assets/img/tds_shield.svg" title="TD Securities" alt="TD Securities" />
                    </a>
                    {
                        this.props.authenticated &&
                        <React.Fragment>
                            <span className="td-icon td-icon-avatar icon-mobile" title="Open User Menu"
                                onClick={this.handleOpenNavRight} ></span>
                            { !this.props.hideMeetingIcon &&
                                <span className="td-icon td-icon-bookAppointment icon-mobile"
                                    onClick={this.showMeeting} ></span>
                            }
                        </React.Fragment>
                    }
                    {
                        !this.props.authenticated &&
                        <React.Fragment>
                            <span className="tds-mobile-login"
                                onClick={this.handleOnLoginClick}>LOGIN</span>
                        </React.Fragment>
                    }
                </div>
                {
                    (this.state.navLeftActive || this.state.navRightActive) &&
                    <div className="tds-mobile-nav-background-overlay active"></div>
                }
                <div className={`tds-mobile-nav-left ${this.state.navLeftActive ? TDS_ACTIVE : ""}`}>
                    <div className="tds-mobile-nav-logo-container">
                        <a
                            href={LINK_TDSECURITIES}
                            className="tds-logo-mobile"
                            title="TD Securities"
                            onClick={this.handleCloseNavLeft}
                        >
                            <img src="/assets/img/tds_shield.svg" title="TD Securities" alt="TD Securities" />
                        </a>
                        <span className="td-icon td-icon-close icon-mobile" title="Close Menu"
                            onClick={this.handleCloseNavLeft}></span>
                    </div>
                    <nav className="tds-mobile-nav-container">
                        {!this.props.hideSearchBar &&
                            <div className="tds-mobile-search-container form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <span className="td-icon td-icon-search icon-small" onClick={() => this.handleSearchClick(this, cowenFlag)} title="Search"></span>
                                        </span>
                                    </div>
                                    <input
                                        name="search"
                                        type="text"
                                        className="form-control"
                                        id="search"
                                        placeholder="Search"
                                        onChange={this.handleSetSearchValue}
                                        onKeyDown={(e) => this.handleKeyPress(e, cowenFlag)}
                                    />
                                </div>
                            </div>
                        }
                        <HeaderNavBarMobileComponent
                            menuItems={HeaderTopNavMenuData}
                            closeNavPanel={this.handleLeftMenuItemClick}
                            showModal={this.props.showModal}
                        />
                    </nav>
                    <nav className="tds-mobile-nav-container">
                        <HeaderNavBarMobileComponent
                            menuItems={isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? HeaderMainNavMenuDataCowen : HeaderMainNavMenuData}
                            closeNavPanel={this.handleLeftMenuItemClick}
                            showModal={this.props.showModal}
                            showQuickLinks = {true} 
                        />
                    </nav>
                </div>
                <div className={`tds-mobile-nav-right ${this.state.navRightActive ? TDS_ACTIVE : ""}`}>
                    <div className="tds-mobile-nav-logo-container">
                        <span className="td-icon td-icon-avatar icon-mobile"></span>
                        <span className="tds-mobile-nav-username">{this.props.user}</span>
                        <span className="td-icon td-icon-close icon-mobile" title="Close User Menu"
                            onClick={this.handleCloseNavRight}></span>
                    </div>
                    <nav className="tds-mobile-nav-container">
                        <HeaderNavBarMobileComponent
                            menuItems={HeaderMobileUserNavData}
                            closeNavPanel={this.handleRightMenuItemClick}
                            showModal={this.props.showModal}
                        />
                    </nav>
                </div>

            </header>
        )
    }
}

export default withRouter(HeaderNavMobileComponent);