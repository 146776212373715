import {
  ROUTE_ONE_PORTAL_LEARN_MORE,
  ROUTE_ONE_PORTAL_REGISTRATION_REQUEST,
} from "../constants/RoutePaths.constants";

export const onFaqClick = (e) => {
  e.preventDefault();
  window.open(
    `${ROUTE_ONE_PORTAL_LEARN_MORE}`,
    "_blank",
    "noopener,noreferrer"
  );
};

export const SWITCHOVER = new Date("2024-10-31");
export const TODAY = new Date();

export const renderCondition = () => {
  //TODO Implement Logic here. Default to FALSE
  return false
  // if (TODAY < SWITCHOVER) {
  //   return true;
  // } else if (TODAY > SWITCHOVER && !this.state.authenticated) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const bannerContent = () => {
  if (TODAY < SWITCHOVER) {
    return (
      <div className="tds-banner-content-container">
        <div className="tds-banner-content-message">
          <p>
            Market Alpha is moving to Multi-factor authentication soon and all
            users are required to user new credentials to login and use the
            site. To create your new credentials, please{" "}
            <a
              className="tds-banner-content-cta-registration"
              href={ROUTE_ONE_PORTAL_REGISTRATION_REQUEST}
            >
              click here.
            </a>
          </p>
          <p>
            If you have already created your new credentials, you may start
            using them after{" "}
            <time className="tds-banner-content-date" dateTime="2024-10-31">
              31.OCT.2024.
            </time>{" "}
            Please continue using the existing login method until then.
          </p>
        </div>
        <button
          className="tds-banner-content-cta-faq"
          onClick={onFaqClick}
        >
          Learn More
        </button>
      </div>
    );
  } else if (TODAY > SWITCHOVER && !this.state.authenticated) {
    return (
      <div className="tds-banner-content-container">
        <div className="tds-banner-content-message">
          <p>
            Market Alpha has moved to Multi-factor authentication and all users
            are required to user new credentials to login and use the site. To
            create your new credentials, please
            <a href={ROUTE_ONE_PORTAL_REGISTRATION_REQUEST}>click here.</a>
          </p>
          <p>
            If you have already created your new credentials, you may start
            using them now.
          </p>
        </div>
      </div>
    );
  }
};
export const onDismiss = () => {
  console.log("anything to be done");
};
