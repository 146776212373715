import React from 'react';
import {Link} from "react-router-dom";
import CompanyDetailsTabComponent from './CompanyDetailsTab.component';
import CompanyActionNotesComponent from './CompanyActionNotes.component';
import FormatedDateComponent from '../misc/formated-date/FormatedDate.component';
import FormatedNumberComponent from '../misc/formated-number/FormatedNumber.component';
import CompanyService from '../../services/Company.service';
import SpinnerComponent from '../spinner/Spinner.component';
import ErrorComponent from '../misc/error/Error.component';
import SideBarComponent from '../side-bar/SideBar.component';
import UserService from '../../services/User.service';
import AclAccessDeniedComponent from '../misc/acl-access-denied/AclAccessDenied.component';
import {
    LINK_PATH_ANALYST_PROFILE_PAGE,
    LINK_SEARCH_PAGE
} from '../../constants/LinkPaths.constants';
import {
    AMPERSAND,
    COWEN_DISCLOSURE_LINK,
    CSS_DISPLAY_BLOCK,
    CSS_DISPLAY_NONE,
    CURRENCY_SIGN_MAPPING,
    CURRENCY_SYMBOL_MAPPING,
    EQUALS,
    ERROR_TYPE,
    FIRM_NAME_COWEN,
    FIRM_NAME_RESEARCH,
    FORWARD_SLASH,
    QUERY_PARAM_FIRM_NAME,
    QUERY_PARAM_TEAM,
    QUESTION_MARK,
    TDS_DATE_FORMAT
} from '../../constants/Misc.constants';
import {ACL_EQUITIES_COVERAGE} from '../../constants/Entitlements.constants';
import {COMPANY_DETAILS_TABS} from '../../constants/CompanyDetails.constants';
import {SEARCH_RESULTS_INDUSTIRES_QUERY_PARAM_NAME} from '../../constants/SearchResults.constants';
import {displayArray, isCowen, isCowenEnabled} from '../../utils/valueUtils';
import CowenFlagContext from "../../system/CowenFlagContext";

export default class CompanyDetailsPageComponent extends React.Component {
    _isMounted = true;
    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            error: false,
            cowenEnabled: false,
            currentTab: COMPANY_DETAILS_TABS.ACTION_NOTES,
            fetchDataForTabs: [true, false, false, false]
        };
        if (this.props && this.props?.match?.params?.issuerId) {
            this.state.issuerId = this.props.match.params.issuerId;
        }
        this.hasAccess = UserService.hasAccess(ACL_EQUITIES_COVERAGE);
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.isRestricted = this.isRestricted.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        this.getData();
        isCowen().then(r => {
            this.setState({ cowenEnabled: r });
        }).catch(e => {
            console.log(e);
        });
    }
    getData() {
        CompanyService.getDetails(this.state.issuerId).then(this.getDataThen).catch(this.getDataError);
    }
    getDataThen(data) {
        this.setState(
            {
                data: data,
                loading: false,
                error: false,
                fetchDataForTabs: [true, false, false, false]
            }
        );
    }
    getDataError(error) {
        this.setState(
            {
                data: null,
                error: true,
                loading: false,
                fetchDataForTabs: [true, false, false, false]
            }
        );
    }
    handleTabChange(tab) {
        const dataLoadedState = this.state.fetchDataForTabs;

        if (tab === COMPANY_DETAILS_TABS.INDUSTRY_NOTES) {
            dataLoadedState[1] = true;
        }
        if (tab === COMPANY_DETAILS_TABS.FLASH_NOTES) {
            dataLoadedState[2] = true;
        }
        if (tab === COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES) {
            dataLoadedState[3] = true;
        }

        this.setState(
            {
                currentTab: tab,
                fetcfetchDataForTabshData: [...dataLoadedState],
                issuerId: this.state.issuerId
            }
        );
    }
    isRestricted() {
        return this.state.data && (this.state.data.restricted || this.state.data.notCovered);
    }

    isSecuritiesEnabled() {
        return this.state.data && (this.state.data.securitiesEnabled);
    }


    getCurrencySymbol() {
        const currency = this.state.data.currency ? this.state.data.currency.toUpperCase() : "";
        if (CURRENCY_SYMBOL_MAPPING[currency])
            return CURRENCY_SYMBOL_MAPPING[currency] + ' ' + CURRENCY_SIGN_MAPPING[currency];
        else
            return currency.toUpperCase() + ' ';
    }
    setPriceGraphLink() {
        return (this.isRestricted()) ? "#" : this.state.data.priceGraphLink
    }

    getAnalyst() {
        return this.state.data.analyst.lastName.concat(
            `, ${this.state.data.analyst.firstName}`
        ).concat(
            (this.state.data.analyst.position) ? ', '.concat(this.state.data.analyst.position) : ''
        );
    }

    getDisclosureSection() {
        return (
            <Link
                to={{ pathname: COWEN_DISCLOSURE_LINK }}
                target="_blank"
                className="tds-company-details-links-title"
                title="Important Disclosures"
            >
                Important Disclosures
            </Link>
        )
    }

    getIndustrySearchLink() {
        const link = LINK_SEARCH_PAGE + QUESTION_MARK + SEARCH_RESULTS_INDUSTIRES_QUERY_PARAM_NAME + EQUALS +
            this.state.data.industry.industryId + AMPERSAND + QUERY_PARAM_FIRM_NAME + EQUALS;
        if (!isCowenEnabled(this.state.cowenEnabled, this.context)) {
            return link + FIRM_NAME_RESEARCH;
        }
        const team = this.getTeam();
        return link + FIRM_NAME_COWEN + AMPERSAND + QUERY_PARAM_TEAM + EQUALS + team;
    }

    getTeam() {
        return this.state.data.analyst.division;
    }

    render() {
        const cowenFlag = this.context;
        return (
            <React.Fragment>
                {
                    !this.hasAccess && <AclAccessDeniedComponent />
                }
                {
                    this.hasAccess &&
                    <div className="tds-company-details-page">
                        <div className="tds-company-details-container">
                            {this.state.loading && !this.state.error && <SpinnerComponent />}
                            {!this.state.loading && this.state.error && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />}
                            {!this.state.loading && !this.state.error &&
                                <React.Fragment>
                                    <h1>{this.state.data.companySymbol} | {this.state.data.companyName}</h1>
                                    <div className="tds-company-details-description">
                                        <h2>Company Description</h2>
                                        {
                                            !this.isRestricted() && this.state.data.securitiesEnabled &&
                                            <p>{this.state.data.companyDescription}</p>
                                        }
                                    </div>
                                    <div className="tds-company-details-stock-summary">
                                        <div className="tds-company-details-stock-summary-row">
                                            <div className="tds-company-details-stock-summary-column label">
                                                Symbol(s)
                                            </div>
                                            <div className="tds-company-details-stock-summary-column value">
                                                {this.state.data.companySymbol}
                                                {
                                                    this.state.data.otherSymbols && this.state.data.otherSymbols.length > 0 &&
                                                    <React.Fragment>
                                                        {', ' + displayArray(this.state.data.otherSymbols)}
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className="tds-company-details-stock-summary-row">
                                            <div className="tds-company-details-stock-summary-column label">
                                                Market
                                            </div>
                                            <div className="tds-company-details-stock-summary-column value">
                                                {
                                                    this.isRestricted() &&
                                                    <React.Fragment>
                                                        Restricted
                                                    </React.Fragment>
                                                }
                                                {
                                                    !this.isRestricted() && this.state.data.securitiesEnabled &&
                                                    <React.Fragment>
                                                        {this.state.data.market}
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className="tds-company-details-stock-summary-row">
                                            <div className="tds-company-details-stock-summary-column label">
                                                Recommendation
                                            </div>
                                            <div className="tds-company-details-stock-summary-column value">
                                                {
                                                    this.isRestricted() &&
                                                    <React.Fragment>
                                                        Restricted
                                                    </React.Fragment>
                                                }
                                                {
                                                    !this.isRestricted() && this.state.data.securitiesEnabled &&
                                                    <React.Fragment>
                                                        {this.state.data.recommendation}
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className="tds-company-details-stock-summary-row">
                                            <div className="tds-company-details-stock-summary-column label">
                                                12 Month Target
                                            </div>
                                            <div className="tds-company-details-stock-summary-column value">
                                                {
                                                    this.isRestricted() &&
                                                    <React.Fragment>
                                                        Restricted
                                                    </React.Fragment>
                                                }
                                                {
                                                    Boolean(!this.isRestricted() && this.state.data.twelveMonthTargetValue && this.state.data.twelveMonthTarget !== "N/A" && this.state.data.twelveMonthTarget !== "NA") && this.state.data.securitiesEnabled &&
                                                    <React.Fragment>
                                                        {
                                                            this.getCurrencySymbol()

                                                        }
                                                        {
                                                            this.state.data.twelveMonthTargetValue > 0 &&
                                                            <FormatedNumberComponent
                                                                number={this.state.data.twelveMonthTargetValue}
                                                                decimal={'2'}
                                                            />
                                                        }
                                                    </React.Fragment>
                                                }
                                                {
                                                    Boolean(!this.isRestricted() && (this.state.data.twelveMonthTarget === "N/A" || this.state.data.twelveMonthTarget === "NA")) && this.state.data.securitiesEnabled &&
                                                    <React.Fragment>
                                                        {
                                                            this.state.data.twelveMonthTarget

                                                        }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        {
                                            !(isCowenEnabled(this.state.cowenEnabled, cowenFlag)) &&
                                            <div className="tds-company-details-stock-summary-row">
                                                <div className="tds-company-details-stock-summary-column label">
                                                    Overall Risk Rating
                                                </div>
                                                <div className="tds-company-details-stock-summary-column value">
                                                    {this.isRestricted() && <React.Fragment>Restricted</React.Fragment>}
                                                    {!this.isRestricted() && this.state.data.securitiesEnabled && (
                                                        <React.Fragment>{this.state.data.overallRiskRating}</React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.isRestricted() &&
                                            <div className="tds-company-details-stock-summary-row">
                                                <div className="tds-company-details-stock-summary-column label">
                                                    Currency
                                                </div>
                                                <div className="tds-company-details-stock-summary-column value">
                                                    {
                                                        this.isRestricted() &&
                                                        <React.Fragment>
                                                            Restricted
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        !this.isRestricted() && this.state.data.securitiesEnabled &&
                                                        <React.Fragment>
                                                            {this.state.data.currency}
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.data.analyst && this.state.data.analyst.id &&
                                            <div className="tds-company-details-stock-summary-row">
                                                <div className="tds-company-details-stock-summary-column label">
                                                    Analyst
                                                </div>
                                                <div className="tds-company-details-stock-summary-column value">
                                                    {this.state.data.analyst && this.state.data.securitiesEnabled &&
                                                        <Link
                                                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH
                                                                + (isCowenEnabled(this.state.cowenEnabled, cowenFlag) ? FIRM_NAME_COWEN : FIRM_NAME_RESEARCH) +
                                                                FORWARD_SLASH + this.state.data.analyst.id}
                                                            title={this.getAnalyst()}
                                                        >
                                                            {this.getAnalyst()}
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.data.industry &&
                                            <div className="tds-company-details-stock-summary-row">
                                                <div className="tds-company-details-stock-summary-column label">
                                                    Industry
                                                </div>
                                                <div className="tds-company-details-stock-summary-column value">
                                                    {this.state.data.securitiesEnabled &&
                                                        <Link
                                                            to={this.getIndustrySearchLink()}

                                                            title={this.state.data.industry.name}
                                                        >
                                                            {this.state.data.industry.name}
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.isRestricted() && !isCowenEnabled(this.state.cowenEnabled, cowenFlag) &&
                                            <div className="tds-company-details-stock-summary-row">
                                                <div className="tds-company-details-stock-summary-column label">
                                                    Last Action Note
                                                </div>
                                                <div className="tds-company-details-stock-summary-column value">
                                                    {
                                                        this.isRestricted() &&
                                                        <React.Fragment>
                                                            Restricted
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        !this.isRestricted() && this.state.data.securitiesEnabled &&
                                                        <FormatedDateComponent
                                                            date={this.state.data.lastActionNoteDate}
                                                            format={TDS_DATE_FORMAT}
                                                        />
                                                    }

                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="tds-company-details-links">
                                        <h3>Links</h3>
                                        <span
                                            className="tds-company-details-links-files"
                                        >
                                            <span className="tds-company-details-links-files-label">
                                                Models:
                                                {
                                                    !this.isRestricted() && !this.state.data.webModelsLinks &&
                                                    <React.Fragment> N/A</React.Fragment>
                                                }
                                            </span>
                                            {
                                                (this.isRestricted() || this.state.data.webModelsLinks) &&
                                                <React.Fragment>
                                                    {
                                                        (this.isRestricted() || this.state.data.webModelsLinks.xls) &&
                                                        <a
                                                            href={this.isRestricted() ? "#" : this.state.data.webModelsLinks.xls}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Models Excel"
                                                            download
                                                            className={this.isRestricted() ? "tds-link-disabled" : ""}
                                                        >
                                                            <span className="td-icon td-icon-download icon-small"></span>
                                                        </a>
                                                    }
                                                    {
                                                        (this.isRestricted() || this.state.data.webModelsLinks.pdf) &&
                                                        <a
                                                            href={this.isRestricted() ? "#" : this.state.data.webModelsLinks.pdf}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            title="Models PDF"
                                                            download
                                                            className={this.isRestricted() ? "tds-link-disabled" : ""}
                                                        >
                                                            <span className="td-icon td-icon-download icon-small"></span>
                                                        </a>
                                                    }
                                                </React.Fragment>
                                            }
                                        </span>

                                        {
                                            this.getDisclosureSection()
                                        }
                                    </div>
                                </React.Fragment>
                            }
                            {
                                !this.isRestricted() && this.isSecuritiesEnabled() &&
                                <div>
                                    <CompanyDetailsTabComponent
                                        tabChange={this.handleTabChange}
                                        tab={this.state.currentTab}
                                    />
                                    <div
                                        className="tds-company-details-tab-content action-notes"
                                        style={
                                            {
                                                'display': this.state.currentTab === COMPANY_DETAILS_TABS.ACTION_NOTES ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                            }
                                        }
                                    >
                                        <CompanyActionNotesComponent
                                            type={COMPANY_DETAILS_TABS.ACTION_NOTES}
                                            team={this.getTeam()}
                                            issuerId={this.state.issuerId}
                                            loadData={this.state.fetchDataForTabs[0]}
                                        />
                                    </div>
                                    <div
                                        className="tds-company-details-tab-content industry-notes"
                                        style={
                                            {
                                                'display': this.state.currentTab === COMPANY_DETAILS_TABS.INDUSTRY_NOTES ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                            }
                                        }
                                    >
                                        <CompanyActionNotesComponent
                                            team={this.getTeam()}
                                            type={COMPANY_DETAILS_TABS.INDUSTRY_NOTES}
                                            issuerId={this.state.issuerId}
                                            teaserOnly={true}
                                            loadData={this.state.fetchDataForTabs[1]}
                                        />
                                    </div>
                                    <div
                                        className="tds-company-details-tab-content flash-notes"
                                        style={
                                            {
                                                'display': this.state.currentTab === COMPANY_DETAILS_TABS.FLASH_NOTES ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                            }
                                        }
                                    >
                                        <CompanyActionNotesComponent
                                            team={this.getTeam()}
                                            type={COMPANY_DETAILS_TABS.FLASH_NOTES}
                                            issuerId={this.state.issuerId}
                                            loadData={this.state.fetchDataForTabs[2]}
                                        />
                                    </div>
                                    <div
                                        className="tds-company-details-tab-content"
                                        style={
                                            {
                                                'display': this.state.currentTab === COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES ? CSS_DISPLAY_BLOCK : CSS_DISPLAY_NONE
                                            }
                                        }
                                    >
                                        <CompanyActionNotesComponent
                                            team={this.getTeam()}
                                            type={COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES}
                                            issuerId={this.state.issuerId}
                                            teaserOnly={true}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                ((this.isRestricted() || !this.isSecuritiesEnabled()) && !this.state.loading) &&
                                <div className="tds-company-details-page-tab-container">
                                    <div className="tds-company-details-notes-table">
                                        <b>No research is available</b>
                                    </div>
                                </div>
                            }
                        </div>
                        <SideBarComponent />
                    </div>
                }
            </React.Fragment>
        )
    }
}
