import { useEffect, useState } from "react";
import { ESG_HEADER_LABEL, ESG_SCORES_FIELD_LABELS, STOCKS_STATISTICS_FIELD_LABELS, STOCKS_STATS_HEADER_LABEL } from "../../constants/CompanyDetails.constants";
import KeyValueTableComponent from "../misc/key-value-table/KeyValueTable.component";
import CompanyService from "../../services/Company.service";
import SpinnerComponent from "../spinner/Spinner.component";
import { ERROR_TYPE, TDS_DATE_FORMAT_FULL_MONTH } from "../../constants/Misc.constants";
import ErrorComponent from "../misc/error/Error.component";
import moment from 'moment';

const CompanyStocksStatisticsAndScoresComponent = (props) => {
 const [keyTerms, setKeyTerms] = useState({});
 const [data, setData] = useState();
 const [loading, setLoading] = useState(false);
 const [hasError, setHasError] = useState(false);

 useEffect(() => {
  const getError = () => {
    setHasError(true);
      setLoading(false);
      setData(null);
  }
    setLoading(true);
    setHasError(false);
    CompanyService.getStocksStatsAndScoresData(props.issuerId).then(data => {
        setData(data);
        setLoading(false);
    }).catch(getError);
 }, [props.issuerId])

 useEffect(() => {
  const getKeyTerms = () => {
    const stocksKeyTerms = [];
    const esgKeyTerms = [];
    const twelveMonthDiv = data?.statistics?.twMonthDivEst;
    if (twelveMonthDiv) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.TWELVE_MONTH_DIV,
        value: twelveMonthDiv
      });
    }
    const twelveMonthTotalReturn = data?.statistics?.twMonthTotalReturn;
    if (twelveMonthTotalReturn) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.TWELVE_MONTH_TOTAL_RETURN,
        value: twelveMonthTotalReturn
      });
    }

    const beta = data?.statistics?.beta;
    if (beta) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.BETA,
        value: beta
      });
    }

    const bookValuePerShare = data?.statistics?.bookValuePerShare;
    if (bookValuePerShare) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.BV_SHARE,
        value: bookValuePerShare
      });
    }

    const cashShare = data?.statistics?.cashShare;
    if (cashShare) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.CASH_SHARE,
        value: cashShare
      });
    }

    const enterpriseVal = data?.statistics?.enterpriseVal;
    if (enterpriseVal) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.ENTERPRISE_VALUE,
        value: enterpriseVal
      });
    }
    const fcfYield = data?.statistics?.fcfYield;
    if (fcfYield) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.FCF_YIELD,
        value: fcfYield
      });
    }
    const floatSharesMM = data?.statistics?.floatSharesMM;
    if (floatSharesMM) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.FLOAT_SHARES,
        value: floatSharesMM
      });
    }
    const navps = data?.statistics?.navps;
    if (navps) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.NAVPS,
        value: navps
      });
    }
    const netdebt = data?.statistics?.netdebt;
    if (netdebt) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.NET_DEBT,
        value: netdebt
      });
    }
    const pricingDate = data?.statistics?.pricingDate;
    if (pricingDate) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.PRICING_DATE,
        value: moment.utc(pricingDate).format(TDS_DATE_FORMAT_FULL_MONTH)
      });
    }
    const roe = data?.statistics?.roe;
    if (roe) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.ROE,
        value: roe
      });
    }
    const roic = data?.statistics?.roic;
    if (roic) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.ROIC,
        value: roic
      });
    }
    const sharesout = data?.statistics?.sharesout;
    if (sharesout) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.DIL_SHARES_OUT,
        value: sharesout
      });
    }
    const shortInterest = data?.statistics?.shortInterest;
    if (shortInterest) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.SHORT_INTEREST,
        value: shortInterest
      });
    }
    const workingCapMM = data?.statistics?.workingCapMM;
    if (workingCapMM) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.WORKING_CAP,
        value: workingCapMM
      });
    }
    const floatCap = data?.statistics?.floatCap;
    if (floatCap) {
      stocksKeyTerms.push({
        key: STOCKS_STATISTICS_FIELD_LABELS.FLOAT_CAP,
        value: floatCap
      });
    }

    const esgScore = data?.esgScore?.score;
    if (esgScore) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.ESG_SCORES,
        value: esgScore
      });
    }
    const esgIndustryPercentile = data?.esgScore?.esgIndustryPctl;
    if (esgIndustryPercentile) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.ESG_INDUSTRY_PERCENTILE,
        value: esgIndustryPercentile
      });
    }
    const materialityInsight = data?.esgScore?.materialityInsight;
    if (materialityInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.ESG_MATERIALITY_INSIGHT,
        value: materialityInsight
      });
    }
    const accessAffordInsight = data?.esgScore?.accessAffordInsight;
    if (accessAffordInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_ACCESS_AFFORDABILITY,
        value: accessAffordInsight
      });
    }
    const airQuality = data?.esgScore?.airQualityInsight;
    if (airQuality) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_AIR_QUALITY,
        value: airQuality
      });
    }
    const businessEthics = data?.esgScore?.businessEthicsInsight;
    if (businessEthics) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_BUSINESS_ETHICS,
        value: businessEthics
      });
    }
    const businessModel = data?.esgScore?.businessModelInsight;
    if (businessModel) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_BUSINESS_MODEL,
        value: businessModel
      });
    }
    const climateChangeInsight = data?.esgScore?.climateChangeInsight;
    if (climateChangeInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_CLIMATE_CHANGE,
        value: climateChangeInsight
      });
    }
    const competitive = data?.esgScore?.competitiveInsight;
    if (competitive) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_COMPETITIVE,
        value: competitive
      });
    }
    const criticalRiskInsight = data?.esgScore?.criticalRiskInsight;
    if (criticalRiskInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_CRITICAL_RISK,
        value: criticalRiskInsight
      });
    }
    const customerPrevInsight = data?.esgScore?.customerPrevInsight;
    if (customerPrevInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_CUSTOMER_PRIVACY,
        value: customerPrevInsight
      });
    }
    const customerWelfareInsight = data?.esgScore?.customerWelfareInsight;
    if (customerWelfareInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_CUSTOMER_WELFARE,
        value: customerWelfareInsight
      });
    }
    const dataSecurityInsight = data?.esgScore?.dataSecurityInsight;
    if (dataSecurityInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_DATA_SECURITY,
        value: dataSecurityInsight
      });
    }
    const ecologicalInsight = data?.esgScore?.ecologicalInsight;
    if (ecologicalInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_ECOLOGICAL,
        value: ecologicalInsight
      });
    }
    const empEngagementInsight = data?.esgScore?.empEngagementInsight;
    if (empEngagementInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_EMPLOYEE_ENGAGEMENT,
        value: empEngagementInsight
      });
    }
    const empHealthInsight = data?.esgScore?.empHealthInsight;
    if (empHealthInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_EMPLOYEE_HEALTH,
        value: empHealthInsight
      });
    }
    const energyInsight = data?.esgScore?.energyInsight;
    if (energyInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_ENERGY,
        value: energyInsight
      });
    }
    const ghgEmissionsInsight = data?.esgScore?.ghgEmissionsInsight;
    if (ghgEmissionsInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_GHG_EMISSIONS,
        value: ghgEmissionsInsight
      });
    }
    const hazMaterialInsight = data?.esgScore?.hazMaterialInsight;
    if (hazMaterialInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_HAZARDOUS_MATERIALS,
        value: hazMaterialInsight
      });
    }
    const humaRightsInsight = data?.esgScore?.humaRightsInsight;
    if (humaRightsInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_HUMAN_RIGHTS,
        value: humaRightsInsight
      });
    }
    const labourPracticesInsight = data?.esgScore?.labourPracticesInsight;
    if (labourPracticesInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_LABOR_PRACTICES,
        value: labourPracticesInsight
      });
    }
    const legalRegulationInsight = data?.esgScore?.legalRegulationInsight;
    if (legalRegulationInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_LEGAL_REGULATION,
        value: legalRegulationInsight
      });
    }
    const materialSourcingInsight = data?.esgScore?.materialSourcingInsight;
    if (materialSourcingInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_MATERIAL_SOURCING,
        value: materialSourcingInsight
      });
    }
    const productLifecycleInsight = data?.esgScore?.productLifecycleInsight;
    if (productLifecycleInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_PRODUCT_LIFECYCLE,
        value: productLifecycleInsight
      });
    }
    const productQualityInsight = data?.esgScore?.productQualityInsight;
    if (productQualityInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_PRODUCT_QUALITY,
        value: productQualityInsight
      });
    }
    const sellingPracticesInsight = data?.esgScore?.sellingPracticesInsight;
    if (sellingPracticesInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_SELLING_PRACTICES,
        value: sellingPracticesInsight
      });
    }
    const supplyChainInsight = data?.esgScore?.supplyChainInsight;
    if (supplyChainInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_SUPPLY_CHAIN,
        value: supplyChainInsight
      });
    }
    const systematicRiskInsight = data?.esgScore?.systematicRiskInsight;
    if (systematicRiskInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_SYSTEMATIC_RISK,
        value: systematicRiskInsight
      });
    }
    const waterWasteInsight = data?.esgScore?.waterWasteInsight;
    if (waterWasteInsight) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.INSIGHT_WATER_WASTEWATER,
        value: waterWasteInsight
      });
    }
    const accessAffordPctl = data?.esgScore?.accessAffordPctl;
    if (accessAffordPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.ACCESS_AND_AFFORDABILITY,
        value: accessAffordPctl
      });
    }
    const airQualityPctl = data?.esgScore?.airQualityPctl;
    if (airQualityPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.AIR_QUALITY,
        value: airQualityPctl
      });
    }
    const busEthicsPctl = data?.esgScore?.busEthicsPctl;
    if (busEthicsPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.BUSINESS_ETHICS,
        value: busEthicsPctl
      });
    }
    const busModelPctl = data?.esgScore?.busModelPctl;
    if (busModelPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.BUSINESS_MODEL,
        value: busModelPctl
      });
    }
    const climateChangePctl = data?.esgScore?.climateChangePctl;
    if (climateChangePctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.CLIMATE_CHANGE,
        value: climateChangePctl
      });
    }
    const competitivePctl = data?.esgScore?.completitivePctl;
    if (competitivePctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.COMPETITIVE,
        value: competitivePctl
      });
    }
    const criticalRiskPctl = data?.esgScore?.criticalRiskPctl;
    if (criticalRiskPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.CRITICAL_RISK,
        value: criticalRiskPctl
      });
    }
    const customerPrivPctl = data?.esgScore?.customerPrivPctl;
    if (customerPrivPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.CUSTOMER_PRIVACY,
        value: customerPrivPctl
      });
    }
    const customerWelfarePctl = data?.esgScore?.customerWelfarePctl;
    if (customerWelfarePctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.CUSTOMER_WELFARE,
        value: customerWelfarePctl
      });
    }
    const dataSecurityPctl = data?.esgScore?.dataSecurityPctl;
    if (dataSecurityPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.DATA_SECURITY,
        value: dataSecurityPctl
      });
    }
    const ecologicalPctl = data?.esgScore?.ecologicalPctl;
    if (ecologicalPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.ECOLOGICAL,
        value: ecologicalPctl
      });
    }
    const empEngagementPctl = data?.esgScore?.empEngagementPctl;
    if (empEngagementPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.EMPLOYEE_ENGAGEMENT,
        value: empEngagementPctl
      });
    }
    const empHealthPctl = data?.esgScore?.empHealthPctl;
    if (empHealthPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.EMPLOYEE_HEALTH,
        value: empHealthPctl
      });
    }
    const energyPctl = data?.esgScore?.energyPctl;
    if (energyPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.ENERGY,
        value: energyPctl
      });
    }
    const ghgEmissionsPctl = data?.esgScore?.ghgEmissionsPctl;
    if (ghgEmissionsPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.GHG_EMISSIONS,
        value: ghgEmissionsPctl
      });
    }
    const humanRightsPctl = data?.esgScore?.humanRightsPctl;
    if (humanRightsPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.HUMAN_RIGHTS,
        value: humanRightsPctl
      });
    }
    const laborPracticesPctl = data?.esgScore?.laborPracticesPctl;
    if (laborPracticesPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.LABOR_PRACTICES,
        value: laborPracticesPctl
      });
    }
    const legalRegulationPctl = data?.esgScore?.legalRegulationPctl;
    if (legalRegulationPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.LEGAL_REGULATION,
        value: legalRegulationPctl
      });
    }
    const materialSourcingPctl = data?.esgScore?.materialSourcingPctl;
    if (materialSourcingPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.MATERIAL_SOURCING,
        value: materialSourcingPctl
      });
    }
    const productLifecyclePctl = data?.esgScore?.productLifecyclePctl;
    if (productLifecyclePctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.PRODUCT_LIFECYCLE,
        value: productLifecyclePctl
      });
    }
    const productQualityPctl = data?.esgScore?.productQualityPctl;
    if (productQualityPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.PRODUCT_QUALITY,
        value: productQualityPctl
      });
    }
    const sellingPracticesPctl = data?.esgScore?.sellingPracticesPctl;
    if (sellingPracticesPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.SELLING_PRACTICES,
        value: sellingPracticesPctl
      });
    }
    const supplyChainPctl = data?.esgScore?.supplyChainPctl;
    if (supplyChainPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.SUPPLY_CHAIN,
        value: supplyChainPctl
      });
    }
    const systematicRiskPctl = data?.esgScore?.systematicRiskPctl;
    if (systematicRiskPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.SYSTEMATIC_RISK,
        value: systematicRiskPctl
      });
    }
	const hazMaterialsPctl = data?.esgScore?.hazMaterialsPctl;
    if (hazMaterialsPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.HAZARDOUS_MATERIALS,
        value: hazMaterialsPctl
      });
    }
    const wasteWaterPctl = data?.esgScore?.wasteWaterPctl;
    if (wasteWaterPctl) {
      esgKeyTerms.push({
        key: ESG_SCORES_FIELD_LABELS.WATER_WASTEWATER,
        value: wasteWaterPctl
      });
    }
    const keyTerms = {"stocksKeyTerms": stocksKeyTerms, "esgKeyTerms": esgKeyTerms};
    return keyTerms;
  }
  setKeyTerms(getKeyTerms());
  }, [data]);

  const showSpinner = () => loading && <SpinnerComponent />;

  const error = () => {
    return hasError && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
}

  return (
    <>
    {showSpinner()}
    {error()}
    {
      !showSpinner() && !error() && !data && 
      <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
    }
    { !showSpinner() && !error() && data && 
      <div className="tds-company-stocks-statistics-scores-container">
        { keyTerms.stocksKeyTerms && keyTerms.stocksKeyTerms.length > 0 && 
            <><section className="tds-company-stocks-statistics-scores-key-terms">
          <div className="tds-company-stocks-statistics-scores-key-terms-card">
            {<>
              <KeyValueTableComponent list={keyTerms.stocksKeyTerms} contained={true} className="key-terms-table" headerLabel={STOCKS_STATS_HEADER_LABEL} />
            </>}
          </div>
        </section>
        </>
        }
        { keyTerms.esgKeyTerms && keyTerms.esgKeyTerms.length > 0 && 
          <section className="tds-company-stocks-statistics-scores-key-terms">
            <div className="tds-company-stocks-statistics-scores-key-terms-card">
              {
                <>
                  <KeyValueTableComponent list={keyTerms.esgKeyTerms} contained={true} className="key-terms-table" headerLabel={ESG_HEADER_LABEL}/>
                </>
              }
            </div>
          </section>
        }
      </div>
    }</>
 )

}

export default CompanyStocksStatisticsAndScoresComponent;