import React from 'react';
import { Redirect } from 'react-router-dom';
import AnalystProfileCardComponent from '../analyst-profile-card/AnalystProfileCard.component';
import AnalystProfileSearchComponent from '../analyst-profile-search/AnalystProfileSearch.component';
import AnalystProfileCoverageListComponent from '../analysts-profile-coverage-list/AnalystProfileCoverageList.component';
import ShowContentComponent from '../../../components/misc/show-content/ShowContent.component';
import SideBar from '../../side-bar/SideBar.component';
import Spinner from '../../spinner/Spinner.component';
import ErrorComponent from '../../misc/error/Error.component';
import AnalystService from '../../../services/Analyst.service';
import { ANALYST_PROFILE_PAGE_TITLE } from '../../../constants/PageTitle.constants';
import {
    LINK_PATH_404_PAGE
} from '../../../constants/LinkPaths.constants';
import {
    FIRM_NAME_RESEARCH,
    ERROR_TYPE,
    TEAM
} from '../../../constants/Misc.constants';
import { getTeam } from '../../../utils/valueUtils';
import UserService from '../../../services/User.service';
import MoneyTalkPageComponent from '../../money-talk-page/MoneyTalkPage.component';
import MoneyTalkService from '../../../services/MoneyTalk.service';

export default class AnalystProfileComponent extends React.Component {
    analystSearchComponentRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            analyst: null,
            firmName: null,
            loading: true,
            error: false,
            analystId: null,
            redirect: false,
            team: TEAM.CA,
            moneyTalkFlag: false
        };
        let { match } = this.props;
        if (match && match.params && match.params.profileId) {
            this.state.analystId = match.params.profileId
        }
        if (match && match.params && match.params.profileId) {
            this.state.firmName = match.params.firmName
        }
        const isStrategy = this.props.location?.pathname?.includes("strategy");
        this.state.team = (UserService.isFicmOnly() || isStrategy) ? "strategy" : this.state.team;

        this.getAnalyst = this.getAnalyst.bind(this);
        this.getAnalystThen = this.getAnalystThen.bind(this);
        this.getAnalystError = this.getAnalystError.bind(this);
        this.renderRedirect = this.renderRedirect.bind(this);
        this.getMoneyTalkFlag = this.getMoneyTalkFlag.bind(this);

    }
    componentDidMount() {
        document.title = ANALYST_PROFILE_PAGE_TITLE;
        this.getAnalyst();
        this.getMoneyTalkFlag();
    }

    getMoneyTalkFlag() {
        MoneyTalkService.getMoneyTalkFlag().then(flagData => {
            if(flagData.flag) {
                const flag = flagData.flag === 'true';
                if(flag) { 
                    this.setState({
                        moneyTalkFlag : true,
                        loading: false,
                    });
                }
            }
        })
    }
    componentDidUpdate(prevProps) {
        var change = false;
        if (prevProps.match) {
            if (prevProps.match.params.profileId !== this.props.match.params.profileId) {
                change = true;
            }
            if (prevProps.match.params.firmName !== this.props.match.params.firmName) {
                change = true;
            }
            if (change) {
                this.setState({
                    analystId: this.props.match.params.profileId,
                    firmName: this.props.match.params.firmName,
                    loading: true
                }, this.getAnalyst);
            }
        }
    }
    getAnalyst() {
        if (this.state.analystId) {
            AnalystService.getAnalyst(this.state.analystId, this.state.firmName).then(this.getAnalystThen).catch(this.getAnalystError);
            if (this.analystSearchComponentRef && this.analystSearchComponentRef.current) {
                this.analystSearchComponentRef.current.handleAnalystChange();
            }
        } else {
            this.getAnalystError(null);
        }
    }
    getAnalystThen(analyst) {
        const isStrategy = this.props.location?.pathname?.includes("strategy");
        if(analyst?.division?.divisionId) {
            this.setState({
                team: (UserService.isFicmOnly() || isStrategy) ? "strategy" : getTeam(analyst.division.divisionId)
            });
        }
        if (!analyst.active) {
            this.setState({
                loading: false,
                redirect: true
            });
        }
        document.title = ANALYST_PROFILE_PAGE_TITLE + analyst.firstName + ' ' + analyst.lastName;
        this.setState({
            analyst: analyst,
            loading: false
        });

    }
    getAnalystError(error) {
        this.setState({
            analyst: null,
            loading: false,
            error: true
        });
    }
    
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={LINK_PATH_404_PAGE} />
        }
    }
    render() {
        window.scrollTo({ top: 0 });
        return (
            <section className="tds-analyst-profile">
                { this.renderRedirect()}
                {
                    this.state.loading &&
                    <React.Fragment>
                        <Spinner minHeight={1200} marginTop={100} />
                    </React.Fragment>
                }
                {
                    !this.state.loading && this.state.error &&
                    <React.Fragment>
                        <div className="tds-three-column-container error">
                            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                        </div>
                        <SideBar hideBookMeeting={true} />
                    </React.Fragment>
                }
                {
                    !this.state.loading && this.state.analyst &&
                    <React.Fragment>
                        <AnalystProfileCardComponent analyst={this.state.analyst} nomeeting={this.state.firmName === FIRM_NAME_RESEARCH} />
                        <SideBar hideBookMeeting={true} />
                        <div className="tds-three-column-container tds-analyst-profile-background-container">
                            <ShowContentComponent
                                if={this.state.analyst.biography}>
                                <h3>Background</h3>
                                <p>
                                    {
                                        this.state.analyst.biography
                                    }
                                </p>
                            </ShowContentComponent>
                        </div>
                        { !this.props.isFicm &&                                       
                            <AnalystProfileCoverageListComponent
                                items={this.state.analyst.issuers}
                            />
                        }
                                                          
                        { this.state.moneyTalkFlag && 
                            <MoneyTalkPageComponent
                                analystProfileFlag={true} 
                                analystId={this.state.analystId}
                                firmId={this.state.analyst.firmId}
                            />
                        }
                        
                        <AnalystProfileSearchComponent
                            ref={this.analystSearchComponentRef}
                            analystId={this.state.analystId}
                            firmName={this.state.firmName}
                            team={this.state.team}
                        />

                    </React.Fragment>
                }
            </section>
        )
    }
}