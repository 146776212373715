import React from "react";

export default function LearnMorePage() {
  return (
    <div className="tds-container">
      <div className="tds-generic-content-page">
        <h1>What is Multi-factor Authentication</h1>

        <p>
          Multi-factor Authentication (MFA) is an extra layer of security to
          protect your accounts. Any time we need to confirm your identity
          online, we'll text or call you with a one-time security verification
          code. we only require ou to use MFA when you login from a device we
          don't recognize. A recognized device is any deice is any device that
          you regularly use to access your TD securities accounts. To view,
          update and add phone numbers to receive one-time security verification
          codes, login to TD Securities One Portal and go to 'Manage Security
          Settings'. Please Note that standard wireless carrier message and data
          rates may apply for text messages
        </p>

        <p>
          <strong>
            Do I need a mobile phone to use Multi-Factor Authentication
          </strong>
        </p>

        <p>
          We only require you to use Multi-factor Authentication when we need to
          verify your identity, for example, when you login from a device we
          don't recognize. A recognized device is any device that you regularly
          use to access your TD Securities accounts. How often we require you to
          use Multi-Factor Authentication depends on your security setting. To
          view and update this, login to TD Securities One Portal and go to
          'Manage Security Settings'.{" "}
        </p>

        <p>
          <strong>How to create login profile</strong>
        </p>

        <p>
          If you are new to TD Securities One Portal and received an invitation
          to register, you can use the link embedded in the email to complete
          your new profile set up. This will take you through a few steps and
          allow you to select your username and password. Note that your
          username is not the same as your email id or existing connect id or
          ACF2 ID. If you had submitted a Registration Request and did not
          receive a Registration Link, get in touch with your Sales Contact or
          One Portal Helpdesk (Email: OnePOrtal@tdsecurities.com, Phone: +1
          888-335-7375)
        </p>
      </div>
    </div>
  );
}
