import React, { useEffect, useState } from "react";
import { DEFAULT_EMP_SELECT, DEFAULT_STATUS, IS_TD_EMPLOYEE_OPTIONS,
  MIFID_II_STATUS_DROPDOWN_LIST, MII_STATUS_DROPDOWN_LIST } from "../../../constants/RequestAccessForm.constants";
import {isEmpty, isNotEmpty} from "../../../utils/valueUtils";
import FormErrorComponent from "../../misc/form-error/FormError.component";
import Select, {components} from 'react-select';
import { ReactSelectDesktopStyles, ReactSelectDesktopStylesError } from '../../../constants/ReactSelect.constants';
import { COMPLIANCE_INFO, REQUEST_ACCESS_FORM_NOTES, REQUEST_ACCESS_FORM_TEXT } from "./RequestAccessForm.constants";
import { getCountries } from "../../../services/Country.service";

const Option = props => {
  return (
      <div style={{
          padding: props.data.padding,
          fontWeight: props.data.fontWeight,
          width: props.data.width,
      }}>
          <components.Option {...props} />
      </div>
  );
};
// prevent the form error from pushing the forms field up
const RequestAccessFormError = () => (
  <div className="tds-landing-request-access-form-error">
    <FormErrorComponent />
  </div> 
)

const RequestAccessFormComponent = props => {

    const [areasOfInterest, setAreasOfInterest] = useState({
        equitiesCanada: {
            checked: false,
            name: 'Equity Research'
        },
        globalMacroStrategy: {
            checked: false,
            name: 'Global Macro & Strategy'
        }
    });
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [occupation, setOccupation] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyAddress1, setCompanyAddress1] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [countries, setCountries] = useState('');
    const [country, setCountry] = useState('');
    const [countryObj, setCountryObj] = useState('default');
    const [countryCode, setCountryCode] = useState();
    const [compliance, setCompliance] = useState('');
    const [complianceSectionVisible, setComplianceSectionVisible] = useState(false);
    const [city, setCity] = useState('');
    const [provinceOrState, setProvinceOrState] = useState('');
    const [zipOrPostalCode, setZipOrPostalCode] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [institutionalContact, setInstitutionalContact] = useState('');
    const [mifidIIStatus, setMifidIIStatus] = useState('');
    const [mifidIIStatusObj, setMifidIIStatusObj] = useState('default');
    const [mIIStatus, setMIIstatus] = useState('');
    const [mIIStatusObj, setMIIstatusObj] = useState('default');
    const [tdEmployeeStatus, setTdEmployeeStatus] = useState('');
    const [tdEmployeeStatusObj, setTdEmployeeStatusObj] = useState('default');
    const [lineOfBusiness, setLineOfBusiness] = useState('');
    const [otherLineOfBusiness, setOtherLineOfBusiness] = useState('');
    const [formSubmitClicked, setFormSubmitClicked] = useState(false)
    const [formErrors, setFormErrors] = useState({
      firstName: true,
      lastName: true,
      companyName: true,
      occupation: true,
      email: true,
      phone: true,
      institutionalContact: true,
      mifidIIStatus: true,
      mifidIIStatusObj: true,
      mIIStatus: true,
      mIIStatusObj: true,
      tdEmployeeStatus: true,
      tdEmployeeStatusObj: true,
      lineOfBusiness: false,
      otherLineOfBusiness: false,
      country: true,
      countryObj: true,
      compliance: false
    })

    /*
      Note: Should consider using a third party library for validation
      if more complicated validation is required.
    */

    // Validation on mandatory input change
    useEffect(() => {
      setFormErrors({
        firstName: firstName ? false : true,
        lastName: lastName ? false : true,
        companyName: companyName ? false : true,
        occupation: occupation ? false : true,
        country: country ? false : true,
        countryObj: countryObj !== 'default' ? false : true,
        email: email ? false : true,
        phone: phone ? false : true,
        institutionalContact: institutionalContact ? false : true,
        mifidIIStatus: mifidIIStatus !== 'default' ? false : true,
        mifidIIStatusObj: mifidIIStatusObj !== 'default' ? false : true,
        mIIStatus: mIIStatus !== 'default' ? false : true,
        mIIStatusObj: mIIStatusObj !== 'default' ? false : true,
        tdEmployeeStatus: tdEmployeeStatus !== 'default' ? false : true,
        tdEmployeeStatusObj: tdEmployeeStatusObj !== 'default' ? false : true,
        lineOfBusiness: tdEmployeeStatus === 'Yes' && isEmpty(lineOfBusiness) ? true : false,
        otherLineOfBusiness: lineOfBusiness === 'Other' && isEmpty(otherLineOfBusiness) ? true : false,
        compliance: complianceSectionVisible && isEmpty(compliance) ? true : false,
      })
    }, [
      firstName,
      lastName,
      companyName,
      occupation,
      country,
      countryObj,
      countryCode,
      email,
      phone,
      institutionalContact,
      mifidIIStatus,
      mifidIIStatusObj,
      mIIStatus,
      mIIStatusObj,
      tdEmployeeStatus,
      tdEmployeeStatusObj,
      lineOfBusiness,
      otherLineOfBusiness,
      compliance,
      complianceSectionVisible
    ])

    useEffect(() => {
      setCountries(getCountries()); 
      if(countryCode === COMPLIANCE_INFO.compliance_country && (isNotEmpty(email) && !email.includes(COMPLIANCE_INFO.compliance_excluded_email_domain)) && tdEmployeeStatus === 'Yes') {
        setComplianceSectionVisible(true);
    } else {
      setComplianceSectionVisible(false);
    }
    }, [countryCode, email, tdEmployeeStatus]);

    const isFormValid = () => {
      return !Object.keys(formErrors).find(k => formErrors[k] === true);
    }

    const handleTdEmployeeStatus = (empStatus) => {
      setTdEmployeeStatus(empStatus.value);
      setTdEmployeeStatusObj(empStatus);
      handleComplianceCheckbox(false);
    }

    const handleLineOfBusiness = (e) => {
      setLineOfBusiness(e.target.value);
    }

    const handleOtherLineOfBusiness = (e) => {
      setOtherLineOfBusiness(e.target.value);      
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (isFormValid()) {
         props.onSubmit({
              areasOfInterest: Object.keys(areasOfInterest)
                  .filter(k => areasOfInterest[k].checked)
                  .map(k => areasOfInterest[k].name),
              firstName,
              lastName,
              companyName,
              occupation,
              companyAddress: (companyAddress1 || companyAddress2)
                  ? `${companyAddress1} ${companyAddress2}`
                  : companyAddress,
              country,
              city,
              zipOrPostalCode,
              email,
              compliance,
              institutionalContact,
              mifidIIStatus,
              mIIStatus,
              tdEmployeeStatus,
              lineOfBusiness,
              otherLineOfBusiness

          });
        }
        setFormSubmitClicked(true);
    }
    const handleChangeCheckbox = (e, key) => {
      setAreasOfInterest({
        ...areasOfInterest,
        [key]: {
            ...areasOfInterest[key],
            checked: e.target.checked
        }
      });
    }
    const handleComplianceCheckbox = (flag) => {
      setCompliance(flag ? true : '');
    }
    const handleCancel = () => {
        props.onCancel();
    }

    const handleEmailOnChange = (email) => {
      setEmail(email);
      if(isEmpty(email) || email.includes(COMPLIANCE_INFO.compliance_excluded_email_domain)) {
        handleComplianceCheckbox(false);
      }
    }

   const handleMIfidStatusStatusChange = (mifidStatus) => {
      setMifidIIStatus(mifidStatus.value);
      setMifidIIStatusObj(mifidStatus); 
  }

  const handleCountryChange = (country) => {
    setCountry(country.label);
    setCountryCode(country.value);
    setCountryObj(country);
    handleComplianceCheckbox(false);
}

  const handleMIStatusChange = (miiStatus) => {
    setMIIstatus(miiStatus.value);
    setMIIstatusObj(miiStatus);
  }

    return (
      <div className="tds-landing-request-access" >
        <div className="tds-landing-request-access-container">
          <span className="tds-landing-logo">
            <img
              src="/assets/img/tds_shield.svg"
              title="TD Securities"
              alt="TD Securities"
            />
          </span>
          <span
            className="tds-landing-form-close td-icon td-icon-close td-interactive-icon"
            onClick={handleCancel}
          ></span>
          <h2>Registration Form</h2>
          <p>
            {REQUEST_ACCESS_FORM_TEXT.TEXT_1}
            <br />
            <br />
            {REQUEST_ACCESS_FORM_TEXT.TEXT_2}
          </p>
          <form onSubmit={handleSubmit} className="tds-landing-request-access-form-container">
            <div className="tds-landing-request-access-form-grid-container">
              <fieldset className="tds-landing-form-check-group tds-landing-form-large-field tds-landing-form-group">
                <legend>
                  <h6>Area(s) of Interest:</h6>
                  Select area(s) of interest for you most read preference:
                </legend>
                {
                    Object.keys(areasOfInterest).map(key => {
                        return (
                            <div className="form-check form-check-inline" key={key}>
                                <input
                                    name={key}
                                    type="checkbox"
                                    id={key}
                                    checked={areasOfInterest[key].checked}
                                    onChange={(e) => handleChangeCheckbox(e, key)}/>
                                <label htmlFor={key}>{areasOfInterest[key].name}</label>
                            </div>
                        );
                    })
                }
              </fieldset>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="firstName">First Name</label>
                <input
                  name="firstName"
                  type="text"
                  className={`form-control ${formSubmitClicked && formErrors.firstName ? "is-invalid": ""}`}
                  id="firstName"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                />
                {
                  formSubmitClicked
                    && formErrors.firstName
                    && <RequestAccessFormError />
                }
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="lastName">Last Name</label>
                <input
                  name="lastName"
                  type="text"
                  className={`form-control ${formSubmitClicked && formErrors.lastName ? "is-invalid": ""}`}
                  id="lastName"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                />
                {
                  formSubmitClicked
                    && formErrors.lastName
                    && <RequestAccessFormError />
                }
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="companyName">Company Name</label>
                <input
                  name="companyName"
                  type="text"
                  className={`form-control ${formSubmitClicked && formErrors.companyName ? "is-invalid": ""}`}
                  id="companyName"
                  onChange={e => setCompanyName(e.target.value)}
                  value={companyName}
                />
                {
                  formSubmitClicked
                    && formErrors.companyName
                    && <RequestAccessFormError />
                }
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="occupation">Occupation</label>
                <input
                  name="occupation"
                  type="text"
                  className={`form-control ${formSubmitClicked && formErrors.occupation ? "is-invalid": ""}`}
                  id="occupation"
                  onChange={e => setOccupation(e.target.value)}
                  value={occupation}
                />
                {
                  formSubmitClicked
                    && formErrors.occupation
                    && <RequestAccessFormError />
                }
              </div>
              <div
                id="company-address"
                className="tds-landing-form-group tds-landing-form-medium-field form-group td-form-group-padding"
              >
                <label htmlFor="companyAddress">Company Address</label>
                <input
                  name="companyAddress"
                  type="text"
                  className="form-control"
                  id="companyAddress"
                  onChange={e => setCompanyAddress(e.target.value)}
                  value={companyAddress}
                />
              </div>
              <div
                id="company-address-line-1"
                className="tds-landing-form-group tds-landing-form-medium-field form-group td-form-group-padding"
              >
                <label htmlFor="companyAddressLine1">Company Address Line 1</label>
                <input
                  name="companyAddressLine1"
                  type="text"
                  className="form-control"
                  id="companyAddressLine1"
                  onChange={e => setCompanyAddress1(e.target.value)}
                  value={companyAddress1}
                />
              </div>
              <div
                id="company-address-line-2"
                className="tds-landing-form-group tds-landing-form-medium-field form-group td-form-group-padding"
              >
                <label htmlFor="companyAddressLine2">Company Address Line 2</label>
                <input
                  name="companyAddressLine2"
                  type="text"
                  className="form-control"
                  id="companyAddressLine2"
                  onChange={e => setCompanyAddress2(e.target.value)}
                  value={companyAddress2}
                />
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="country">Country</label>
                    <Select
                        options={countries}
                        components={{ Option }}
                        value={countryObj}
                        isMulti={false}
                        onChange={handleCountryChange}
                        styles={formSubmitClicked && formErrors.countryObj ? ReactSelectDesktopStylesError : ReactSelectDesktopStyles}
                        fontColor='#ae1100'
                        fontWeight='bold'
                    />
                {
                  formSubmitClicked
                    && formErrors.countryObj 
                    && <RequestAccessFormError />
                }
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                <label htmlFor="city">City</label>
                <input
                  name="city"
                  type="text"
                  className="form-control"
                  id="city"
                  onChange={e => setCity(e.target.value)}
                  value={city}
                />
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                <label htmlFor="provinceState">Province/State</label>
                <input
                  name="provinceState"
                  type="text"
                  className="form-control"
                  id="provinceState"
                  onChange={e => setProvinceOrState(e.target.value)}
                  value={provinceOrState}
                />
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                <label htmlFor="zipPostalCode">Zip/Postal Code</label>
                <input
                  name="zipPostalCode"
                  type="text"
                  className="form-control"
                  id="zipPostalCode"
                  onChange={e => setZipOrPostalCode(e.target.value)}
                  value={zipOrPostalCode}
                />
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  type="text"
                  className={`form-control ${formSubmitClicked && formErrors.email ? "is-invalid": ""}`}
                  id="email"
                  onChange={e => handleEmailOnChange(e.target.value)}
                  value={email}
                />
                {
                  formSubmitClicked
                    && formErrors.email
                    && <RequestAccessFormError />
                }
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="phone">Phone</label>
                <input
                  name="phone"
                  type="text"
                  className={`form-control ${formSubmitClicked && formErrors.phone ? "is-invalid": ""}`}
                  id="phone"
                  onChange={e => setPhone(e.target.value)}
                  value={phone}
                />
                {
                  formSubmitClicked
                    && formErrors.email
                    && <RequestAccessFormError />
                }
              </div>
            </div>
            <div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="institutionalContact">
                  Institutional or Corporate Sales Contact:
                </label>
                <input
                  name="institutionalContact"
                  type="text"
                  className={`form-control ${formSubmitClicked && formErrors.institutionalContact ? "is-invalid": ""}`}
                  id="institutionalContact"
                  onChange={e => setInstitutionalContact(e.target.value)}
                  value={institutionalContact}
                />
                {
                  formSubmitClicked
                    && formErrors.institutionalContact
                    && <RequestAccessFormError />
                }
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="mifid2Status">Mifid II Status:</label>
                    <Select
                        options={MIFID_II_STATUS_DROPDOWN_LIST}
                        components={{ Option }}
                        value={mifidIIStatusObj}
                        isMulti={false}
                        onChange={handleMIfidStatusStatusChange}
                        styles={formSubmitClicked && formErrors.mifidIIStatusObj ? ReactSelectDesktopStylesError : ReactSelectDesktopStyles}
                        placeholder={DEFAULT_STATUS}
                        fontColor='#ae1100'
                        fontWeight='bold'
                    />
                {
                  formSubmitClicked
                    && formErrors.mifidIIStatusObj
                    && <RequestAccessFormError />
                }
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="miiStatus">MII Status</label>
                <Select
                        options={MII_STATUS_DROPDOWN_LIST}
                        components={{ Option }}
                        value={mIIStatusObj}
                        isMulti={false}
                        onChange={handleMIStatusChange}
                        styles={formSubmitClicked && formErrors.mIIStatusObj ? ReactSelectDesktopStylesError : ReactSelectDesktopStyles}
                        placeholder={DEFAULT_STATUS}
                        fontColor='#ae1100'
                        fontWeight='bold'
                    />
                {
                  formSubmitClicked
                    && formErrors.mIIStatusObj
                    && <RequestAccessFormError />
                }
              </div>
            </div>
            <div className="tds-td-employee-form-container">
              <div className="tds-landing-form-group tds-landing-form-small-field form-inline td-form-group-padding tds-landing-form-required">
                <label htmlFor="firstName">Are you a TD Employee?</label>
                <div style={{width:'250px'}}>
                  <Select
                      options={IS_TD_EMPLOYEE_OPTIONS}
                      components={{ Option }}
                      value={tdEmployeeStatusObj}
                      isMulti={false}
                      onChange={handleTdEmployeeStatus}
                      styles={formSubmitClicked && formErrors.tdEmployeeStatusObj ? ReactSelectDesktopStylesError : ReactSelectDesktopStyles}
                      placeholder={DEFAULT_EMP_SELECT}
                      fontColor='#ae1100'
                      fontWeight='bold'
                  />
                  {
                    formSubmitClicked
                      && formErrors.tdEmployeeStatusObj
                      && <RequestAccessFormError />
                  }
                </div>
              </div>
              <div className="tds-business-line-form-group tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                { tdEmployeeStatus === 'Yes' &&
                  <React.Fragment>
                    <fieldset onChange={handleLineOfBusiness}>
                      <legend>If you are a TD employee please select your line of business:</legend>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="lineOfBusinessRadio" id="lineOfBusinessRadio1" value="TD Wealth IA"/>
                          <label className="form-check-label" for="radio1">TD Wealth IA</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="lineOfBusinessRadio" id="lineOfBusinessRadio2" value="TD Wealth PAIR"/>
                          <label className="form-check-label" for="radio2">TD Wealth PAIR</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="lineOfBusinessRadio" id="lineOfBusinessRadio3" value="TD Securities"/>
                          <label className="form-check-label" for="radio3">TD Securities</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="lineOfBusinessRadio" id="lineOfBusinessRadio4" value="Other"/>
                          <label className="form-check-label" for="radio4">Other (specify):</label>
                      </div>
                    </fieldset>
                    { formSubmitClicked
                      && formErrors.lineOfBusiness
                      && <RequestAccessFormError />
                    }
                    { lineOfBusiness === 'Other' &&
                      <input
                        name="otherLineOfBusiness"
                        onChange={handleOtherLineOfBusiness}
                        type="text"
                        className={`form-control`}
                        id="otherLineOfBusiness"
                      />                      
                    } 
                    { formSubmitClicked
                      && formErrors.otherLineOfBusiness
                      && <RequestAccessFormError />
                    }  
                  </React.Fragment>
                } 
                 <br/>                          
              </div>
             
              { complianceSectionVisible && 
                <><div className="request-access-form-compliance">
                  <legend>
                    {COMPLIANCE_INFO.compliance_note}
                  </legend>
                  <div className="form-check form-check-inline tds-landing-form-group tds-landing-form-required ">
                    <input
                      name="compliance"
                      type="checkbox"
                      id="compliance"
                      onChange={(e) => handleComplianceCheckbox(e.target.checked)} />
                    <label htmlFor="compliance">{COMPLIANCE_INFO.compliance_checkBox_note}</label>
                  </div>
                  {formSubmitClicked
                    && formErrors.compliance
                    && <RequestAccessFormError />}
                </div></>
              }
              <div className="tds-landing-request-access-form-notes">
                <ul>
                  {REQUEST_ACCESS_FORM_NOTES.Foot_Notes.map((notes, index) => (
                    <li key={index}>
                    {notes}
                    </li>
                  ))}
               </ul>
               <font> {REQUEST_ACCESS_FORM_NOTES.Compliance_Contacts_Note} </font>
              </div>
            </div>
            <div className="tds-landing-form-buttons">
              <button className="btn td-btn-secondary-clear" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn td-btn-primary-light" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
}

export default RequestAccessFormComponent;
