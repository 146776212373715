import React, { useEffect, useState } from "react";

export default function Banner({
  content,
  customStyles,
  renderCondition,
  date,
  onDismiss,
}) {
  const [isVisible, setIsVisible] = useState(renderCondition());
  useEffect(() => {
    setIsVisible(renderCondition());
  }, [renderCondition]);

  const handleClose = () => {
    setIsVisible(false);
    if (onDismiss) {
      onDismiss();
    }
  };
  return (
    isVisible && (
      <div className="tds-banner" style={customStyles}>
        <div className="tds-banner-content">{content()}</div>
        <button className="tds-banner-close" onClick={handleClose}>
          X
        </button>
      </div>
    )
  );
}
