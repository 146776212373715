import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ACL_SIDEBAR_QUICK_LINKS_DISSEMINATION_POLICY_LINK, ACL_SIDEBAR_QUICK_LINKS_SALES_LITERATURE_LINK } from "../../constants/Entitlements.constants";
import UserService from "../../services/User.service";
import { isCowen, isCowenEnabled } from "../../utils/valueUtils";
import { COWEN_COVERAGE_UNIVERSE, COWEN_DISCLOSURE_LINK, ERROR_TYPE } from "../../constants/Misc.constants";
import { LINK_EQUITIES_COVERAGE_ADVANCED_FILTER_PAGE, LINK_EQUITIES_RESEARCH_ANALYST_PAGE, LINK_EQUITIES_RESEARCH_COMPANY_PAGE, LINK_EQUITIES_RESEARCH_INDUSTRY_PAGE, LINK_MONEY_TALK_INTERVIEWS_PAGE, LINK_PATH_DISEMMINATION_POLICY_PAGE, LINK_PATH_SALES_LITERATURE_PAGE } from "../../constants/LinkPaths.constants";
import { buildSecureLink } from "../../services/SecureLink.service";
import CowenFlagContext from "../../system/CowenFlagContext";
import { TDS_DROPDOWN_ACTIVE } from "../../constants/HeaderNav.constants";
import MoneyTalkService from "../../services/MoneyTalk.service";
import ErrorComponent from "../misc/error/Error.component";

const QuickLinks = (props) => {

    const [cowenEnabled, setCowenEnabled] = useState(false);
    const [open, setOpen] = useState(false);
    const cowenFlag = useContext(CowenFlagContext);
    const hasAccessSalesLiterature = UserService.hasAccess(ACL_SIDEBAR_QUICK_LINKS_SALES_LITERATURE_LINK);
    const hasAccessDisseminationPolicy = UserService.hasAccess(ACL_SIDEBAR_QUICK_LINKS_DISSEMINATION_POLICY_LINK);
    const isEqUser =  UserService.isEQ();
    const [hasError, setHasError] = useState(false);
    const[flag, setFlag] = useState(false);

    useEffect(() => {
        const getError = () => {
            setHasError(true);
            setFlag(false);
          }
        isCowen().then(flag => {
            setCowenEnabled(flag);
        }).catch(e => {
            console.log(e);
        });
        MoneyTalkService.getMoneyTalkFlag().then(flagData => {
            if(flagData.flag) {
                setFlag(flagData.flag === 'true');
            } else {
                setFlag(false);
            }
        }).catch(getError);
       
    }, []);

    const closePanel = () => {
        if (props.closeNavPanel) {
            props.closeNavPanel();
        }
    }

    const handleOnClick = () => {
        if (open) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }

      const error = () => {
        return hasError && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
    }

    const getHeader = () => {
        if(props.closeNavPanel) {
            return (
                <a
                    title={"Quick Links"}
                    className={
                        "tds-mobile-navbar-dropdown-link"+
                        `${open ? TDS_DROPDOWN_ACTIVE : ""}`
                    }
                    onClick={handleOnClick} 
                >
                    <h3>Quick Links
                        {open && (
                        <span className="td-icon td-icon-upCaret icon-mobile"></span>
                        )}
                        {!open && (
                        <span className="td-icon td-icon-downCaret icon-mobile"></span>
                        )}
                    </h3>
                </a>
            )
        } else {
                return (<h3>Quick Links</h3>
            )
        }
    }
     
    return (
        <div className="tds-quick-links-container">
            {getHeader()}
            { (!props.closeNavPanel || open) && 
                <ul onClick={closePanel}>
                    <li>
                        <a
                            href="https://www.tdsecurities.com/ca/en/home-page"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="TD Securities"
                        >TD Securities</a>
                    </li>
                    <li>
                        <a
                            href="https://economics.td.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="TD Economics"
                        >TD Economics</a>
                    </li>
                    {hasAccessSalesLiterature &&
                        <li>
                            <Link
                                to={LINK_PATH_SALES_LITERATURE_PAGE}
                                title="Sales Literature"
                            >
                                Sales Literature
                            </Link>
                        </li>
                    }
                    {!isCowenEnabled(cowenEnabled, cowenFlag) &&
                        hasAccessDisseminationPolicy &&
                        <li>
                            <Link
                                to={LINK_PATH_DISEMMINATION_POLICY_PAGE}
                                title="Dissemination Policy"
                            >
                                Dissemination Policy
                            </Link>
                        </li>
                    }
                    {isEqUser &&
                        <React.Fragment>
                            <li>
                                <Link
                                    to={{ pathname: COWEN_DISCLOSURE_LINK }}
                                    target="_blank"
                                    title="Important Disclosures"
                                >
                                    Important Disclosures
                                </Link>
                            </li>

                            <li>
                                <Link className="link" to={LINK_EQUITIES_COVERAGE_ADVANCED_FILTER_PAGE} >
                                    <span className="tds-filter-text">Coverage Universe - Advanced Filter</span>
                                </Link>
                            </li>

                            {isCowenEnabled(cowenEnabled, cowenFlag) &&
                                <li>
                                    <a
                                        href={buildSecureLink(COWEN_COVERAGE_UNIVERSE)}
                                        target="_parent"
                                        rel="noopener noreferrer"
                                        title="Download Excel - Coverage Universe"
                                    >
                                        Download Excel - Coverage Universe
                                    </a>
                                </li>
                            }
                            {!isCowenEnabled(cowenEnabled, cowenFlag) &&
                                <>
                                    <li>
                                        <Link
                                            to={LINK_EQUITIES_RESEARCH_ANALYST_PAGE}
                                            title="Analysts"
                                        >
                                            Analysts
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={LINK_EQUITIES_RESEARCH_COMPANY_PAGE}
                                            title="Companies"
                                        >
                                            Companies
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={LINK_EQUITIES_RESEARCH_INDUSTRY_PAGE}
                                            title="Industry"
                                        >
                                            Industry
                                        </Link>
                                    </li>
                                </>
                            }
                        </React.Fragment>
                    }
                    {!error() && flag && 
                        <li>
                            <Link className="link" to={LINK_MONEY_TALK_INTERVIEWS_PAGE} >
                                <span className="tds-filter-text">MoneyTalk Interviews</span>
                            </Link>
                        </li>
                    }
                </ul>
            }
        </div>
    );
}
export default QuickLinks;