const base = 'TD One Portal';
const divider = ' | ';
export const HOME_PAGE_TITLE = base + divider + 'Home';
export const EQUITY_OVERVIEW_PAGE_TITLE = base + divider + 'Equities Overview';
export const CALENDAR_PAGE_TITLE = base + divider + 'Calendar';
export const SEARCH_PAGE_TITLE = base + divider + 'Search';
export const ANALYST_PAGE_TITLE = base + divider + 'Analysts';
export const ANALYST_TEAMS_PAGE_TITLE = base + divider + 'Analyst Teams';
export const ANALYST_PROFILE_PAGE_TITLE = base + divider + 'Analyst' + divider;
export const PUBLICATIONS_PAGE_TITLE = base + divider;
export const RATES_TITLE = 'Rates';
export const RATES_PAGE_TITLE = base + divider + RATES_TITLE;
export const FX_TITLE = 'FX';
export const FX_PAGE_TITLE = base + divider + FX_TITLE;
export const COMMODITIES_TITLE = 'Commodities';
export const COMMODITIES_PAGE_TITLE = base + divider + COMMODITIES_TITLE;
export const MACRO_TITLE = 'Macro';
export const MACRO_PAGE_TITLE = base + divider + MACRO_TITLE;
export const EMERGING_MARKETS_TITLE = "Emerging Markets";
export const EMERGING_MARKETS_PAGE_TITLE = base + divider + EMERGING_MARKETS_TITLE;
export const FOUR_O_FOUR_PAGE_TITLE = base;

export const UNITED_STATES_TITLE = 'United States';
export const CANADA_TITLE = 'Canada';
export const ASIA_PACIFIC_TITLE = 'Asia-Pacific';
export const JAPAN_TITLE = 'Japan';
export const NEW_ZEALAND_TITLE = 'New Zealand';
export const AUSTRALIA_TITLE = 'Australia';
export const EUROPE_TITLE = 'Europe';
export const EUROZONE_TITLE = 'Eurozone';
export const UNITED_KINGDOM_TITLE = 'United Kingdom';
export const SCANDIES_TITLE = 'Scandies';
export const ASIA_TITLE = 'Asia';
export const CHINA_TITLE = 'China';
export const ASIA_EX_CHINA_TITLE = 'Asia Ex-China';
export const LATIN_AMERICA_TITLE = 'Latin America';
export const EMEA_TITLE = 'EMEA';

export const UNITED_STATES_PAGE_TITLE = base + divider + UNITED_STATES_TITLE;
export const CANADA_PAGE_TITLE = base + divider + CANADA_TITLE;
export const ASIA_PACIFIC_PAGE_TITLE = base + divider + ASIA_PACIFIC_TITLE;
export const JAPAN_PAGE_TITLE = base + divider + JAPAN_TITLE;
export const NEW_ZEALAND_PAGE_TITLE = base + divider + NEW_ZEALAND_TITLE;
export const AUSTRALIA_PAGE_TITLE = base + divider + AUSTRALIA_TITLE;
export const EUROPE_PAGE_TITLE = base + divider + EUROPE_TITLE;
export const EUROZONE_PAGE_TITLE = base + divider + EUROZONE_TITLE;
export const UNITED_KINGDOM_PAGE_TITLE = base + divider + UNITED_KINGDOM_TITLE;
export const SCANDIES_PAGE_TITLE = base + divider + SCANDIES_TITLE;
export const ASIA_PAGE_TITLE = base + divider + ASIA_TITLE;
export const CHINA_PAGE_TITLE = base + divider + CHINA_TITLE;
export const ASIA_EX_CHINA_PAGE_TITLE = base + divider + ASIA_EX_CHINA_TITLE;
export const LATIN_AMERICA_PAGE_TITLE = base + divider + LATIN_AMERICA_TITLE;
export const EMEA_PAGE_TITLE = base + divider + EMEA_TITLE;

export const RESEARCH_PAGE_TITLE = base + divider + 'Research';
export const COVERAGE_PAGE_TITLE = base + divider + 'Coverage';
export const COVERAGE_ADVANCED_FILTER_PAGE_TITLE = base + divider + 'Advanced Filter';
export const CONF_CALLS_PAGE_TITLE = base + divider + 'Conference Calls';

export const INDEX_MARKET_STRUCTURES_PAGE_TITLE = base + divider + 'Index Market Structures';
export const EXCHANGE_TRADED_FUNDS_PAGE_TITLE = base + divider + 'Exchange Traded Funds (ETFs)';
export const CONFERENCE_CALL_OTHER_MEDIA_TITLE = base + divider + 'Conference Calls Other Media';

export const EXCHANGE_TRADED_FUNDS_DETAILS_PAGE_TITLE = base + divider + 'ETF Details';

export const ASSET_SECURITIZATION_PAGE_TITLE = base + divider + "Asset Securitization";
export const INVESTMENT_GRADE_PAGE_TITLE = base + divider + "Investment Grade";
export const MONEY_TALK_PAGE_TITLE = base + divider + 'Money Talk';