import React from 'react';
import SpinnerComponent from '../spinner/Spinner.component';
import ErrorComponent from '../misc/error/Error.component';
import CompanyService from '../../services/Company.service';
import PaginationComponent from '../pagination/Paginator.component';
import CompanyActionNoteItem from './CompanyActionNoteItem.component';
import {
    COMPANY_DETAILS_ACTION_NOTES_PAGE_SIZE,
    COMPANY_DETAILS_TABS,
} from '../../constants/CompanyDetails.constants';
import {
    ERROR_TYPE
} from '../../constants/Misc.constants';
import CompanyStocksStatisticsAndScores from './CompanyStocksStatisticsAndScores.component';
import TimeSeriesGridComponent from './TimeSeriesGrid.component';

export default class CompanyActionNotesComponent extends React.Component {
    _isMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            loading: true,
            data: null,
            error: false,
            loadData: this.props.loadData
        };
        this.getData = this.getData.bind(this);
        this.getDataThen = this.getDataThen.bind(this);
        this.getDataError = this.getDataError.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
        if (this.state.loadData) {
            this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.loadData !== prevProps.loadData && this.props.loadData) {
            this.getData();
        }
    }


    getData() {
        const params = {
            size: COMPANY_DETAILS_ACTION_NOTES_PAGE_SIZE,
            type: this.props.type,
            page: this.state.currentPage
        }
        CompanyService.getNotes(this.props.issuerId, params).then(this.getDataThen).catch(this.getDataError)
    }
    getDataThen(data) {
        this.setState(
            {
                data: data,
                loading: false,
                error: false
            }
        );
    }
    getDataError(error) {
        this.setState(
            {
                data: null,
                error: true,
                loading: false
            }
        );
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page,
                data: null,
                loading: true,
                error: false
            },
            this.getData
        );
    }
    render() {
        return (
            <React.Fragment>
                {
                    this.state.loading && !this.state.error && this.props.type !== COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES && 
                    <SpinnerComponent />
                }
                {
                    !this.state.loading && this.state.error && this.props.type !== COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES && 
                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                }
                {
                    !this.state.loading && !this.state.error && this.state.data && this.state.data.total === 0 && 
                    this.props.type !== COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES && 
                    <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                }
                {
                    !this.state.loading && !this.state.error && this.state.data && this.state.data.results && this.state.data.results.length > 0 && this.props.type !== COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES &&
                    <>
                        <div className="tds-company-details-notes-table">
                            <div className="tds-company-details-notes-row header">
                                <div className="tds-company-details-notes-column date">
                                    Date
                                </div>
                                <div className="tds-company-details-notes-column headline">
                                    Headline
                                </div>
                                <div className="tds-company-details-notes-column type">
                                    Type
                                </div>
                            </div>
                        </div>
                        <div className="tds-company-details-notes-table">
                            {
                                this.state.data && this.state.data.results &&
                                this.state.data.results.map((item, index) => (
                                    <CompanyActionNoteItem
                                        key={'tds-company-details-notes-item-' + this.props.type + index}
                                        data={item}
                                        team={this.props.team}
                                        teaserOnly={this.props.teaserOnly}
                                        type={this.props.type}
                                    />
                                ))
                            }
                        </div>
                        {this.state.data && this.state.data.results && this.state.data.results.length > 0 &&
                            <div className="tds-company-details-notes-pagination-container">
                                <PaginationComponent
                                    currentPage={this.state.currentPage}
                                    resultsPerPage={COMPANY_DETAILS_ACTION_NOTES_PAGE_SIZE}
                                    total={this.state.data.total}
                                    goToPage={this.goToPage}
                                />
                            </div>
                        }
                    </>
                }
                {this.props.type === COMPANY_DETAILS_TABS.STOCKS_STATISTICS_SCORES && 
                    <>
                        <TimeSeriesGridComponent issuerId={this.props.issuerId} />
                        <CompanyStocksStatisticsAndScores issuerId={this.props.issuerId} />
                    </>
                }
            </React.Fragment>
            
        )
    }
}
