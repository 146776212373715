
import React, { useEffect, useState } from "react";
import {  ACL_MONEY_TALK } from "../../constants/Entitlements.constants";
import UserService from "../../services/User.service";
import ErrorComponent from "../misc/error/Error.component";
import { MONEY_TALK_PAGE_SIZE, ERROR_TYPE, MONEY_TALK_KEYWORDS_PLACEHOLDER } from "../../constants/Misc.constants";
import SpinnerComponent from "../spinner/Spinner.component";
import AclAccessDeniedComponent from "../misc/acl-access-denied/AclAccessDenied.component";
import SearchServiceV2 from '../../services/v2/Search.service';
import Select from 'react-select';
import { ReactSelectDesktopStyles } from "../../constants/ReactSelect.constants";
import PaginationComponent from '../pagination/Paginator.component';
import MoneyTalkSection from "./MoneyTalkSection.component";
import { MONEY_TALK_PAGE_TITLE } from "../../constants/PageTitle.constants";
import MoneyTalkService from "../../services/MoneyTalk.service";
import { Link, Redirect, useLocation, useHistory } from 'react-router-dom';
import { LINK_HOME_PAGE, LINK_MONEY_TALK_INTERVIEWS_PAGE } from "../../constants/LinkPaths.constants";
import queryString from 'query-string';

export default function MoneyTalkPageComponent(props) {
    const [data, setData] = useState();
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [keywords, setKeywords] = useState(null);
    const [analysts, setAnalysts] = useState(null);
    const [analyst, setAnalyst] = useState(null);
    const [page, setPage] = useState(1);
    const [tag, setTag] = useState(null);
    const hasAccess = UserService.hasAccess(ACL_MONEY_TALK);
    const [redirect, setRedirect] = useState(false);
    let urlQueryParams = queryString.parse(useLocation().search);
    const [text, setText] = useState(urlQueryParams?.tag ? urlQueryParams?.tag : "");
    let history = useHistory();

    useEffect(() => {
        let analystsData = [];
        const getError = () => {
            setHasError(true);
              setLoading(false);
              setAnalysts(null);
              analystsData = null;
              setData(null);
          }
        SearchServiceV2.getAnalystDropDownData().then(data => {
            analystsData.push(...data.analysts);
            analystsData.sort((a, b) => a.label.localeCompare(b.label));
            if(urlQueryParams?.analystId) {
                let analystTmp = (analystsData.filter(analyst => {
                    return (analyst.value === parseInt(urlQueryParams?.analystId) && analyst.firmId === parseInt(urlQueryParams?.firmId));
                }));
                setAnalyst(analystTmp);
                setText(analystTmp[0].label);
            }
            setLoading(false);
        }).catch(getError);
        setAnalysts(analystsData);
        
    }, [urlQueryParams?.analystId, urlQueryParams?.firmId]);

    //this hook is to redirect to home page when MoneyTalk flag is false.
    useEffect(() => {
        const getError = () => {
            setHasError(true);
              setLoading(false);
              setAnalysts(null);
              setData(null);
              setRedirect(false);
        }
        setLoading(true);
        setHasError(false);
        MoneyTalkService.getMoneyTalkFlag().then(flagData => {
            if(flagData.flag) {
                setLoading(false);
                const flag = flagData.flag === 'true';
                if(!flag) { 
                    setRedirect(true);
                }
            } else {
                setRedirect(false);
            }   
        }).catch(getError);
    }, []);

    useEffect(() => {
        if(!props?.analystProfileFlag) {
            document.title = MONEY_TALK_PAGE_TITLE;
        }
        const getError = () => {
            setHasError(true);
              setLoading(false);
              setAnalysts(null);
              setData(null);
              setKeywords(null);
          }
        let params = {
            page: page,
            analystId: analyst?.value ? analyst.value : props?.analystProfileFlag ? props.analystId : urlQueryParams?.analystId,
            firmId: analyst?.firmId ? analyst.firmId : props?.analystProfileFlag ? props.firmId : urlQueryParams?.firmId,
            searchText: keywords,
            tags: tag ? tag : urlQueryParams?.tag,
            size: props?.analystProfileFlag? 1 : MONEY_TALK_PAGE_SIZE,
        };
        setLoading(true);
        setHasError(false);
        MoneyTalkService.getData(params).then(data => {
            setData(data);
            setLoading(false);  
                
        }).catch(getError);
    }, [page, analyst, props?.analystProfileFlag, props?.analystId, props?.firmId, urlQueryParams?.analystId, urlQueryParams?.firmId, urlQueryParams?.tag, keywords, tag]);

    const goToPage = (page) => {
        setPage(page);
    }

    const showSpinner = () => loading && <SpinnerComponent />;

    const accessDenied = () => {
        return !hasAccess && <AclAccessDeniedComponent />;
    }
    const error = () => {
        return hasError && <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
    }

    const redirectToHome = () => redirect && <Redirect from={'*'} to={LINK_HOME_PAGE} />

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value);
    }
    const handleAnalystChange = (obj) => {
        if(obj !== null) {
            history.push(LINK_MONEY_TALK_INTERVIEWS_PAGE);
            setAnalyst(obj);
            setText(obj.label);
            setTag(null);
            setKeyword("");
            setKeywords(null); 
            setData(null); 
            setPage(1);
        } else {
            setAnalyst(null);
            setText(null);
            setData(null);
            history.push(LINK_MONEY_TALK_INTERVIEWS_PAGE);
        }    
    }

    const handleKeywordSearchClick = () => {
        setKeywords(keyword); 
        setText(keyword);
        setTag(null);
        setAnalyst(null);
        setPage(1);
    }

    const setTagData = (tag) => {
        if(tag !== null) {
        if(urlQueryParams?.tag) {
            setTag(tag);  
        }
        setTag(tag);
        setText(tag);
        setKeyword("");
        setKeywords(null); 
        setAnalyst(null);
        setPage(1);
        } else {
            setText(null);
        }
    }

    const getHeader = () => {
        if(props.analystProfileFlag && data?.posts?.length > 0) {
            return (
                <div className="tds-research-content"><h3>Interviews</h3>
                    <Link
                        className="view-all-money-talk-link" 
                        to={LINK_MONEY_TALK_INTERVIEWS_PAGE+"?analystId="+props?.analystId+"&firmId="+props?.firmId}
                        title={'View All'}
                    >
                        View All &gt;
                    </Link>
                </div>
            );
        } else if(!props.analystProfileFlag) {
            return (<h1>MoneyTalk Interviews</h1>);
        }
    }

    return (
        <React.Fragment>
            {accessDenied()}
            {redirectToHome()}
            {getHeader()}
            {error()}
            {

                hasAccess && !hasError && ((!props.analystProfileFlag) || (props.analystProfileFlag && data && data?.posts?.length > 0)) &&
                <div className={!props.analystProfileFlag ? "tds-money-talk-container" : "tds-three-column-money-talk-container"}>
                        <div>
                            {!props.analystProfileFlag && 
                                <>
                                    <div className="tds-money-talk form-group">
                                        <input
                                            className="form-control"
                                            name="keyword"
                                            type="text"
                                            value={keyword}
                                            onChange={handleKeywordChange}
                                            placeholder={MONEY_TALK_KEYWORDS_PLACEHOLDER} />
                                        <span
                                            className="td-icon td-icon-search icon-money-talk"
                                            onClick={handleKeywordSearchClick}
                                            title="Search"
                                        ></span>
                                    </div>

                                    <div className="tds-money-talk-select">
                                            <Select
                                                key="analyst"
                                                options={analysts}
                                                isMulti={false}
                                                value={analyst}
                                                name="analyst"
                                                onChange={handleAnalystChange}
                                                styles={ReactSelectDesktopStyles}
                                                placeholder='Select Analyst(s)'
                                                isClearable={true} />
                                            {error() &&
                                                <Select styles={ReactSelectDesktopStyles} placeholder="Select Analyst" isDisabled={true} />}
                                </div>
                                </>
                            }     
                            <div className={!props.analystProfileFlag ? "tds-money-talk-container" : "tds-three-column-container tds-analyst-profile-search-publications"}>
                            {text && 
                                <div className="tds-money-talk-sub-header">Results for '{text}'</div>}
                                {data?.posts?.map(
                                    (sectionData, index) => {
                                        return (
                                        <>
                                            <a
                                                href={sectionData.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title={sectionData.url}
                                                className={'tds-money-talk-title'}
                                                key={index}
                                            > 
                                                <MoneyTalkSection
                                                    key={'money-talk-section-component' + index}
                                                    data={sectionData} setTagData={setTagData} analystProfileFlag={props?.analystProfileFlag}/>
                                            </a>
                                        </>   
                                        )
                                    }
                                )}
                                {!showSpinner() && !hasError && data && data.posts.length === 0 && 
                                    <ErrorComponent type={ERROR_TYPE.NO_RECORDS} />
                                }
                            </div>
                            {
                                !props?.analystProfileFlag && !showSpinner() && data && data.total > 0 &&
                                <PaginationComponent
                                    currentPage={page ? page : 1}
                                    resultsPerPage={MONEY_TALK_PAGE_SIZE}
                                    total={data?.total}
                                    goToPage={goToPage}
                                />
                            }
                        </div>
                </div>
            }
        </React.Fragment>
    );
};